import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const PessoaApiUrl = process.env.REACT_APP_API_URL + "/pessoa";

export function useGetAllPessoas () {
  const { data, error, isLoading } = useSWR(`${PessoaApiUrl}s`, fetchWrapper.get)
 
  return {
    pessoas: data,
    isLoading,
    isError: error
  }
}

export function useGetBuscaPessoa (search = null, tag = null, page = 1, tipoVinculo = null, vinculo = null, nc = null, nnc = null, bl = null, obs = null, nobs = null, indicacao = null, completa = false) {
  tipoVinculo = tipoVinculo || null;
  search = search?.trim()?.length > 3 ? search : null;
  // console.log(`${PessoaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&bl=${bl}`);
  const { data, error, isLoading } = useSWR((search || tag || (tipoVinculo && vinculo) || (nc || nnc || bl || obs || nobs || indicacao)) ? `${PessoaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}&completa=${completa}` : null, fetchWrapper.get);
  // console.log(data);
 
  return {
    pessoas: data || {pessoas:[]},
    isLoading,
    isError: error
  }
}

export function useGetEstatisticasPessoa (search = null, tag = null, tipoVinculo = null, vinculo = null, nc = null, nnc = null, bl = null, obs = null, nobs = null, indicacao = null) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${PessoaApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`);
  const { data, error, isLoading } = useSWR(`${PessoaApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`, fetchWrapper.get);
 
  // console.log(isLoading);
  // console.table(data);

  return {
    pessoas: data || {},
    isLoading,
    isError: error
  }
}

export function useGetLastPessoas (page = 1) {
  const { data, error, isLoading } = useSWR(`${PessoaApiUrl}s/last?page=${page}`, fetchWrapper.get)
 
  return {
    pessoas: data,
    isLoading,
    isError: error
  }
}

export function useGetPessoa (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${PessoaApiUrl}/${id}` : null, fetchWrapper.get)
    // console.log(data);

    return {
      pessoa: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchPessoa (pessoa) {
  return fetchWrapper.patch(PessoaApiUrl, pessoa);
}

export function postPessoa (pessoa) {
  return fetchWrapper.post(PessoaApiUrl, pessoa);
}

export function delPessoa (pessoa) {
  return fetchWrapper.delete(PessoaApiUrl, pessoa);
}
