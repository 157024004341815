
import { Button, Card, CardContent, CardHeader, TextField } from "@mui/material";
import PaginaBase from "../components/PaginaBase";
import Msg from "../components/Msg";
import { useRef, useState } from "react";
import { trocaSenhaUsuario } from "../../admin/services/usuarioService";

function TrocaSenha() {

  const username = useRef("");
  const pwd = useRef("");
  const novopwd = useRef("");
  const checkpwd = useRef("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  // const navigate = useNavigate();

  async function handleTrocar(e) {
    e.preventDefault();
    // console.log(username.current?.value, pwd.current?.value);
    if (username.current?.value === "" || pwd.current?.value === "" || novopwd.current?.value === "" || checkpwd.current?.value === "") {
      setIsError(true);
      setMsg("Preencha os campos corretamente!");
    }
    else if(novopwd.current?.value !== checkpwd.current?.value) {
      setIsError(true);
      setMsg("Nova senha não confere!");
    }
    else {
      setLoading(true);
      setIsError(false);
      setMsg("Alterando senha...");
      trocaSenhaUsuario(pwd.current.value, novopwd.current.value)
      .then((data)=> {
        setMsg(data.message);
      })
      .catch((err)=>{
        setIsError(true);
        setMsg(err.message)
      })
      .finally(() => {
        setLoading(false)
      });
      // console.log(data);
    }
  }

    return (
      <PaginaBase>
        <Card sx={{maxHeight: "28rem", marginTop:"3rem", maxWidth: "92%", opacity: "0.95"}}>
          <CardHeader title="Trocar senha"/>
          <CardContent sx={{textAlign: "center"}}>
            <form>
            <TextField type="password" name="pwd" inputRef={pwd} label="Senha atual" fullWidth sx={{marginTop:"0.4rem", marginBottom: "0.4rem"}} required error={isError}/>
            <TextField type="password" name="novopwd" inputRef={novopwd} label="Nova senha" fullWidth sx={{marginTop:"0.4rem", marginBottom: "0.4rem"}} required error={isError}/>
            <TextField type="password" name="checkpwd" inputRef={checkpwd} label="Confima nova senha" fullWidth sx={{marginTop:"0.4rem", marginBottom: "0.4rem"}} required error={isError}/>
            <Msg msg={msg} tipo={(isError) ? "error" : loading ? "info" : "success" } onClose={()=>setMsg("")} block></Msg>
            <Button variant="contained" size="large" sx={{marginTop:"20px"}} type="submit" onClick={(e)=>handleTrocar(e)} disabled={loading}>Trocar</Button>
            </form>
          </CardContent>
        </Card>
      </PaginaBase>
    );
  }
  export default TrocaSenha;