import { useState } from "react";
import PaginaBase from "../components/PaginaBase";
import Lista from "../components/Lista";

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InventoryIcon from "@mui/icons-material/Inventory";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import WorkIcon from "@mui/icons-material/Work";

import {
  useGetLastPessoas,
  useGetBuscaPessoa,
} from "../../pessoa/services/pessoaService";
import { useGetLastCasos } from "../../caso/services/casoService";
import {
  useGetLastEmpresas,
  useGetBuscaEmpresa,
} from "../../empresa/services/empresaService";
import { useGetLastClientes } from "../../cliente/services/clienteService";
import { useGetLastDocumentos } from "../../documento/services/documentoService";
import GraficoEstatisticas from "../components/GraficoEstatisticas.js";

function Home() {
  const [pageLastPessoas, setPageLastPessoas] = useState(1);
  const [pageNTPessoas, setPageNTPessoas] = useState(1);

  const [pageLastCasos, setPageLastCasos] = useState(1);

  const [pageLastEmpresas, setPageLastEmpresas] = useState(1);
  const [pageNTEmpresas, setPageNTEmpresas] = useState(1);

  const [pageLastClientes, setPageLastClientes] = useState(1);
  const [pageLastDocumentos, setPageLastDocumentos] = useState(1);

  const {
    pessoas: lastPessoas,
    isLoading: isLoadingLPessoas,
    isError: isErrorLPessoas,
  } = useGetLastPessoas(pageLastPessoas);

  const {
    pessoas: ntPessoas,
    isLoading: isLoadingNTPessoas,
    isError: isErrorNTPessoas,
  } = useGetBuscaPessoa(
    null,
    null,
    pageNTPessoas,
    null,
    null,
    null,
    true,
    null,
    null,
    true,
    null,
    false
  );

  const {
    casos: lastCasos,
    isLoading: isLoadingLCasos,
    isError: isErrorLCasos,
  } = useGetLastCasos(pageLastCasos);

  const {
    empresas: lastEmpresas,
    isLoading: isLoadingLEmpresas,
    isError: isErrorLEmpresas,
  } = useGetLastEmpresas(pageLastEmpresas);

  const {
    empresas: ntEmpresas,
    isLoading: isLoadingNTEmpresas,
    isError: isErrorNTEmpresas,
  } = useGetBuscaEmpresa(
    null,
    null,
    pageNTEmpresas,
    null,
    null,
    null,
    true,
    null,
    null,
    true,
    null,
    false
  );

  const {
    clientes: lastClientes,
    isLoading: isLoadingLClientes,
    isError: isErrorLClientes,
  } = useGetLastClientes(pageLastClientes);
  const {
    documentos: lastDocumentos,
    isLoading: isLoadingLDocumentos,
    isError: isErrorLDocumentos,
  } = useGetLastDocumentos(pageLastDocumentos);

  return (
    <PaginaBase xsSpacing={1.2}>
      <Lista
        icone={<PersonAddIcon />}
        titulo={"Últimas pessoas"}
        linkTitulo="pessoas"
        itens={lastPessoas?.pessoas}
        currentPage={pageLastPessoas}
        setPage={setPageLastPessoas}
        totalPages={lastPessoas?.totalPages}
        isError={isErrorLPessoas}
        isLoading={isLoadingLPessoas}
        linkItem="pessoas"
      />

      <Lista
        icone={<PersonAddIcon />}
        titulo={"Pessoas sem tratamento"}
        linkTitulo="pessoas"
        itens={ntPessoas?.pessoas}
        currentPage={pageNTPessoas}
        setPage={setPageNTPessoas}
        totalPages={ntPessoas?.totalPages}
        isError={isErrorNTPessoas}
        isLoading={isLoadingNTPessoas}
        linkItem="pessoas"
      />
      <GraficoEstatisticas tipo="pessoa"/>
      <Lista
        icone={<InventoryIcon />}
        titulo={"Últimos casos"}
        linkTitulo="casos"
        itens={lastCasos?.casos}
        currentPage={pageLastCasos}
        setPage={setPageLastCasos}
        totalPages={lastCasos?.totalPages}
        isError={isErrorLCasos}
        isLoading={isLoadingLCasos}
        linkItem="casos"
      />

      <Lista
        icone={<ApartmentIcon />}
        titulo={"Últimas empresas"}
        linkTitulo="empresas"
        itens={lastEmpresas?.empresas}
        currentPage={pageLastEmpresas}
        setPage={setPageLastEmpresas}
        totalPages={lastEmpresas?.totalPages}
        isError={isErrorLEmpresas}
        isLoading={isLoadingLEmpresas}
        linkItem="empresas"
      />

      <Lista
        icone={<ApartmentIcon />}
        titulo={"Empresas sem tratamento"}
        linkTitulo="empresas"
        itens={ntEmpresas?.empresas}
        currentPage={pageNTEmpresas}
        setPage={setPageNTEmpresas}
        totalPages={ntEmpresas?.totalPages}
        isError={isErrorNTEmpresas}
        isLoading={isLoadingNTEmpresas}
        linkItem="empresas"
      />
      <GraficoEstatisticas tipo="empresa"/>

      <Lista
        icone={<WorkIcon />}
        titulo={"Últimos clientes"}
        linkTitulo="clientes"
        itens={lastClientes?.clientes}
        currentPage={pageLastClientes}
        setPage={setPageLastClientes}
        totalPages={lastClientes?.totalPages}
        isError={isErrorLClientes}
        isLoading={isLoadingLClientes}
        linkItem="clientes"
      />

      <Lista
        icone={<AssignmentIcon />}
        titulo={"Últimas documentos"}
        linkTitulo="documentos"
        itens={lastDocumentos?.documentos}
        currentPage={pageLastDocumentos}
        setPage={setPageLastDocumentos}
        totalPages={lastDocumentos?.totalPages}
        isError={isErrorLDocumentos}
        isLoading={isLoadingLDocumentos}
        linkItem="documentos"
      />
      <GraficoEstatisticas tipo="documento"/>

    </PaginaBase>
  );
}
export default Home;
