import { useState, useEffect, useContext, useCallback } from "react";
// import { mutate as mutateKey } from "swr";
import { utils, writeFile } from "xlsx";
import { Document, Packer, Paragraph, TextRun, Table, TableCell, TableRow } from "docx";
import PaginaBase from "../../global/components/PaginaBase";
import Lista from "../../global/components/Lista";
import GraficoEstatisticas from "../../global/components/GraficoEstatisticas";
import AlertaDialog from "../../global/components/AlertaDialog";
import {
  TextField,
  IconButton,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { useGetAllTipos } from "../../admin/services/tipoService";
import { VinculoAutocomplete } from "../../global/components/VinculoAutocomplete";
import { ColorModeContext } from "../../App";

function Busca() {
  const colorMode = useContext(ColorModeContext);

  const [tipo, setTipo] = useState("Pessoa");
  const [tag, setTag] = useState("");
  const [tipoVinculo, setTipoVinculo] = useState("");
  const [vinculo, setVinculo] = useState(null);
  const [inputText, setinputText] = useState("");
  const [buscando, setBuscando] = useState(false);
  const [nc, setNc] = useState(null);
  const [nnc, setNnc] = useState(null);
  const [bl, setBl] = useState(null);
  const [obs, setObs] = useState(null);
  const [nobs, setNObs] = useState(null);
  const [indicacao, setIndicacao] = useState(null);

  const [searchText, setSearchText] = useState(null);
  const [stipoVinculo, setSTipoVinculo] = useState("");
  const [svinculo, setSVinculo] = useState(null);
  const [stag, setSTag] = useState("");
  const [snc, setSNc] = useState(null);
  const [snnc, setSNnc] = useState(null);
  const [sbl, setSBl] = useState(null);
  const [sobs, setSObs] = useState(null);
  const [snobs, setSNObs] = useState(null);
  const [sindicacao, setSIndicacao] = useState(null);

  const [relCount, setRelCount] = useState(0);

  const [openConfirmaGerar, setOpenConfirmaGerar] = useState(false);
  
  const tiposArquivo = ["excel", "word"];
  const [tipoArquivo, setTipoArquivo] = useState("excel");
  
  const [camposRel, setCamposRel] = useState([]); //campos que constarão no relatório
  const [campos, setCampos] = useState([]); //campos disponíveis para o tipo

  // Cria um mapa para buscar legendas a partir dos campos
  const campoParaLegenda = {
    "_id": "Id",
    "nome": "Nome",
    "alcunhas": "Alcunhas",
    "tags": "Tags",
    "nc": "Nada Consta",
    "bl": "Entrevista",
    "mae": "Nome da mãe",
    "rg": "RG",
    "cpf": "CPF",
    "dn": "Data de nascimento",
    "obs": "Observação",
    "indicacao": "Indicação",
    "dtEvento": "Data do evento",
    "cnpj": "CNPJ",
    "telefone": "Telefone",
    "contato": "Contato",
    "dtInicio": "Data de início",
    "dtFim": "Data do fim",
    "url": "URL",
    "texto": "Texto"
  };

  const [alertaOpen, setAlertaOpen] = useState(false);
  const [msgAlerta, setMsgAlerta] = useState("");

  const { tipos, isLoadingTipos, isErrorTipos } = useGetAllTipos();
  // console.log(tipos);

  const [tags, setTags] = useState([]);
  // console.log(tags);

  const strTipo = tipo ? tipo.toLocaleLowerCase() : "pessoa";

  const tipofn = `useGetBusca${
    strTipo.charAt(0).toUpperCase() + strTipo.slice(1)
  }`;

  const busca = require("../../" +
    strTipo +
    "/services/" +
    strTipo +
    "Service.js")[tipofn];
  // console.log(inputText, "../../" + strTipo + "/services/" + strTipo + "Service.js", tipofn);

  const {
    [`${strTipo}s`]: itens,
    isLoading,
    isError,
  } = strTipo === "pessoa" || strTipo === "empresa"
    ? busca(
        searchText,
        stag,
        null,
        stipoVinculo ? `${stipoVinculo.toLowerCase()}s` : null,
        svinculo,
        snc,
        snnc,
        sbl,
        sobs,
        snobs,
        sindicacao,
        true
      )
    : busca(
        searchText,
        tag,
        null,
        tipoVinculo ? `${tipoVinculo.toLowerCase()}s` : null,
        vinculo,
        true
      );

  const checkCount = () =>
    itens[`${strTipo}s`].filter((item) => item?.checked === true).length;

  const handleSearchChange = (e) => {
    setBuscando(false);
    let lowerCase = e.target.value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9\-./ ]/g, "");
    setinputText(lowerCase);
  };

  function handleTipoChange(e) {
    setTag("");
    setVinculo(null);
    setTipoVinculo("");
    setBuscando(false);
    setTipo(e.target.value);
    setTags(tipos?.find((tipo) => tipo.nome === e.target.value)?.tags);
  }

  function handleLimpar() {
    setinputText("");
    setSVinculo(null);
    setSTipoVinculo("");
    setSTag("");
    setSNc(null);
    setSBl(null);
    setSNnc(null);
    setSObs(null);
    setSNObs(null);
    setSIndicacao(null);

    setSearchText("");
    setVinculo(null);
    setTipoVinculo("");
    setTag("");
    setNc(null);
    setBl(null);
    setNnc(null);
    setObs(null);
    setNObs(null);
    setIndicacao(null);
    setBuscando(false);
  }

  //retorna linha transformada legenda, estrutura
  function transformarObjeto(itemCopy, tipoArquivo) {
    // Cria o novo objeto com as legendas como chaves
    let novoObjeto = tipoArquivo === "excel" ? {} : [];

    if(tipoArquivo === "word") { // completa campos nulos ou inexistentes com vazio para alinhamento correto da tabela
      let itemAux = {};
      
      for( let i=0; i < camposRel.length; i++) {
        itemAux[camposRel[i]] = itemCopy[camposRel[i]] || "";
      }

      itemCopy = {...itemAux};
    }
    
    // Itera sobre cada campo do itemCopy
    for (let [campo, valor] of Object.entries(itemCopy)) {


      if(camposRel.some(item=> campo === item)) { //somente inclui se campos na lista selecionada
        const legenda = campoParaLegenda[campo] || campo;

        switch (campo) { //campos especiais com tratamento
          case "tags":
            valor = valor ? valor.join(",") : null;
            break;
          case "dn":
            valor = valor ? new Date(valor).toLocaleDateString() : null
            break;
          case "nc":
          case "bl":
            valor = valor ? "Sim" : "Não"
            break;
          case "obs":
            valor = valor ? "Sim" : "Não"
            break;
          default:
            break;
        }

        if(tipoArquivo === "excel"){ //retorna para excel um objeto normal com uma propriedade por coluna já com legenda
          novoObjeto[legenda] = valor;
        }
        else {  //retorna para word um array de objetos celula de word
          novoObjeto.push(new TableCell({
            children: [new Paragraph(valor)],
          }));
        }
      }
    }
    
    // Adiciona os campos extras com suas respectivas transformações
    return novoObjeto;
  }

  /* get state data and export to DOC */
  function exportFileDOC() {
    const filtro = itens[`${strTipo}s`].some((item) => item.checked === true);
    // console.log(filtro);
    let data;

    if (filtro) {
      data = [...itens[`${strTipo}s`].filter((item) => item.checked === true)];
    } else {
      data = [...itens[`${strTipo}s`]];
    }

    let rows = data.map((item) => {
      let itemCopy = {...item};
      delete itemCopy.checked;
      delete itemCopy.matchCount;
      
      return new TableRow({
        children: transformarObjeto(itemCopy, tipoArquivo),
      });
    });

    let titCells = camposRel.map(titulo=> new TableCell({ //monta titulos das tabelas
      children: [new Paragraph({
        children: [new TextRun({
          text: campoParaLegenda[titulo],
          allCaps: true,
          bold: true
        })],
      })],
    }));

    const table = new Table({
      // columnWidths: [3505, 5505],
      rows: [
          new TableRow({
              children: titCells,
              tableHeader: true
          }),
          ...rows
        ],
    });

    const doc = new Document({
      sections: [ {children: [table]}],
    });

    Packer.toBlob(doc).then((blob) => { 
      // Cria uma URL de objeto para o Blob
      const url = window.URL.createObjectURL(blob);

      // Cria um elemento de link dinâmico para download
      const a = document.createElement('a');
      a.href = url;
      
      a.download = `${tipo}${
        tipoVinculo && "_x_" + tipoVinculo
      }_${new Date().toLocaleDateString()}.doc`; // Nome do arquivo para download

      document.body.appendChild(a);
      a.click();

      // Remove o link da árvore do DOM e libera a URL
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);      
    });    

  }


  /* get state data and export to XLSX */
  const exportFileXLS = () => {
    const filtro = itens[`${strTipo}s`].some((item) => item.checked === true);
    // console.log(filtro);
    let data;

    if (filtro) {
      data = [...itens[`${strTipo}s`].filter((item) => item.checked === true)];
    } else {
      data = [...itens[`${strTipo}s`]];
    }

    let rows = data.map((item) => {
      let itemCopy = {...item};
      delete itemCopy.checked;
      delete itemCopy.matchCount;
      
      return transformarObjeto(itemCopy, tipoArquivo);
    });
    
    /* generate worksheet from state */
    const ws = utils.json_to_sheet(rows);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, tipo);
    /* export to XLSX */
    writeFile(
      wb,
      `${tipo}${
        tipoVinculo && "_x_" + tipoVinculo
      }_${new Date().toLocaleDateString()}.xlsx`
    );
  };

  function handleConfirmaGerar() {
    setTipoArquivo("excel");
    setCamposRel(["nome"]);
    setCampos(()=> {
      if (buscando && itens[`${strTipo}s`]?.length > 0) {

        const todasAsKeys = new Set();

        itens[`${strTipo}s`].forEach(obj => {
            Object.keys(obj).forEach(key => {
              if(key !== "matchCount" && key !== "checked")
                todasAsKeys.add(key);
            });
        });
        
        return Array.from(todasAsKeys);
      }

    });

    setOpenConfirmaGerar(true);

  }

  function handleGerar(confirma) {
    if(confirma) {
      setOpenConfirmaGerar(false);
      if (buscando && itens[`${strTipo}s`]?.length > 0) {
        if(tipoArquivo === "excel")
          exportFileXLS();
        else
          exportFileDOC();
      // alert("Relatório");
      }
      else {
        setMsgAlerta("Selecione ao menos 1 item para o relatório!")
        setAlertaOpen(true);
      }
    }
    else {
      handleCloseDialogGerar();
    }
  }

  function handleCloseDialogGerar() {
    setOpenConfirmaGerar(false);
  }

  function handleChangeTipoVinculo(str) {
    setBuscando(false);
    setVinculo("");
    setTipoVinculo(str);
  }

  function handleChangeVinculo(str) {
    setBuscando(false);
    setVinculo(str);
  }

  function isBusca() {
    return (inputText?.trim()?.length > 3 || tag || nc || nnc || bl || obs || nobs || indicacao)
  }

  const handleSearch = (e) => {
    e.preventDefault();
    setBuscando(false);

    if (tipo === "") return;

    if(isBusca()) {
      setSearchText(inputText);
      setSVinculo(vinculo);
      setSTipoVinculo(tipoVinculo);
      setSTag(tag);
      setSNc(nc);
      setSBl(bl);
      setSNnc(nnc);
      setSObs(obs);
      setSNObs(nobs);
      setSIndicacao(indicacao);
      setBuscando(true);
    }
  };

  function handleCheck(e) {
    setBuscando(false);
    if (e.target.name === "nc") {
      setNc(e.target.checked);
      if (e.target.checked) {
        setBl(!e.target.checked);
        setNnc(!e.target.checked);
      }
    } else if (e.target.name === "bl") {
      setBl(e.target.checked);
      if (e.target.checked) setNc(!e.target.checked);
    } else if (e.target.name === "nnc") {
      setNnc(e.target.checked);
      if (e.target.checked) setNc(!e.target.checked);
    }
  }

  function handleSearchObsCheck(e) {
    if (e.target.name === "obs") {
      setObs(e.target.checked);
      if (e.target.checked) setNObs(!e.target.checked);
    } else if (e.target.name === "nobs") {
      setNObs(e.target.checked);
      if (e.target.checked) setObs(!e.target.checked);
    }
  }

  function opcoes(tipo) {
    switch (tipo) {
      case "Pessoa":
        return (
          <>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Marcado como Nada Consta">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nc"
                      color="success"
                      checked={nc || false}
                      onClick={(e) => handleCheck(e)}
                    />
                  }
                  label="NC"
                />
              </Tooltip>
              <Tooltip title="Não marcado como Nada Consta">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nnc"
                      color="success"
                      checked={nnc || false}
                      onClick={(e) => handleCheck(e)}
                    />
                  }
                  label="Sem NC"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Marcado como Entrevista">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bl"
                      color="error"
                      checked={bl || false}
                      onClick={(e) => handleCheck(e)}
                    />
                  }
                  label="Entrevista"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Somente com observação">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="obs"
                      color="info"
                      checked={obs || false}
                      onClick={(e) => handleSearchObsCheck(e)}
                    />
                  }
                  label="Com observação"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Somente sem observação">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nobs"
                      color="info"
                      checked={nobs || false}
                      onClick={(e) => handleSearchObsCheck(e)}
                    />
                  }
                  label="Sem observação"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Somente com indicação">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nobs"
                      color="success"
                      checked={indicacao || false}
                      onClick={(e) => setIndicacao(e.target.checked)}
                    />
                  }
                  label="Com indicação"
                />
              </Tooltip>
            </Grid>
          </>
        );
      case "Empresa":
        return (
          <>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Marcado como Nada Consta">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nc"
                      color="success"
                      checked={nc || false}
                      onClick={(e) => handleCheck(e)}
                    />
                  }
                  label="NC"
                />
              </Tooltip>
              <Tooltip title="Não marcado como Nada Consta">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nnc"
                      color="success"
                      checked={nnc || false}
                      onClick={(e) => handleCheck(e)}
                    />
                  }
                  label="Sem NC"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Marcado como Entrevista">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="bl"
                      color="error"
                      checked={bl || false}
                      onClick={(e) => handleCheck(e)}
                    />
                  }
                  label="Entrevista"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Somente com observação">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="obs"
                      color="info"
                      checked={obs || false}
                      onClick={(e) => handleSearchObsCheck(e)}
                    />
                  }
                  label="Com observação"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Somente sem observação">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nobs"
                      color="info"
                      checked={nobs || false}
                      onClick={(e) => handleSearchObsCheck(e)}
                    />
                  }
                  label="Sem observação"
                />
              </Tooltip>
            </Grid>
            <Grid
              item
              flexGrow={1}
              sx={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              <Tooltip title="Somente com indicação">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="nobs"
                      color="success"
                      checked={indicacao || false}
                      onClick={(e) => setIndicacao(e.target.checked)}
                    />
                  }
                  label="Com indicação"
                />
              </Tooltip>
            </Grid>
          </>
        );
      default:
        return null;
    }
  }

  const checkMaxRegistros = useCallback(()=>{ //caso encontrados mais de 5000 registros exibir alerta
    // console.log(itens?.[`${strTipo}s`]?.length);
    if(Array.isArray(itens?.[`${strTipo}s`]) && itens?.[`${strTipo}s`]?.length > 4999) {
      setMsgAlerta("Foram encontrados mais de 5000 registros, refine sua busca!")
      setAlertaOpen(true);    
    }
  }, [itens, strTipo])

  useEffect(() => {
    // console.log("useEffect!");

    setTags(tipos?.find((x) => x.nome === tipo)?.tags);
    checkMaxRegistros();
  }, [tipos, tipo, checkMaxRegistros, itens]);

  return (
    <PaginaBase>
      <Grid
        item
        xs={12}
        md={4}
        style={{ paddingTop: "1.2rem", paddingLeft: "0px" }}
      >
        <Card sx={{ marginBottom: "1.5rem", opacity: "0.95" }} elevation={10}>
          <CardHeader
            avatar={<SearchIcon />}
            title="Busca geral"
            titleTypographyProps={{
              fontSize: "1em",
              fontWeight: 700,
              letterSpacing: "0",
            }}
          />
          <CardContent>
            <form>
              <Grid container direction={"column"} gap={2}>
                <Grid item>
                  <TextField
                    id="searchbar"
                    label="Digite algum texto para busca"
                    variant="outlined"
                    placeholder="Busca..."
                    size="small"
                    sx={{ width: "calc(100% - 50px)" }}
                    onChange={handleSearchChange}
                    value={inputText}
                    inputProps={{ type: "search" }}
                    error={inputText?.trim()?.length < 4 && inputText?.trim()?.length > 0 }
                    helperText="Mínimo de 4 caracteres para busca"   
                  />
                  <Tooltip title="Buscar">
                    <IconButton
                      aria-label="search"
                      onClick={handleSearch}
                      type="submit"
                    >
                      <SearchIcon
                        color={colorMode.mode === "light" ? "primary" : ""}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid display="flex" sx={{ flexWrap: "wrap" }}>
                  {tipos && (
                    <FormControl size="small" fullWidth>
                      <InputLabel id="tipo-label" shrink>
                        Tipo
                      </InputLabel>
                      <Select
                        labelId="tipo-label"
                        label="Tipo"
                        id="tipo"
                        value={tipo}
                        displayEmpty
                        fullWidth
                        onChange={handleTipoChange}
                      >
                        <MenuItem value="">Escolha um tipo</MenuItem>
                        {isErrorTipos ? (
                          <MenuItem key="Erro" value="Erro">
                            Erro
                          </MenuItem>
                        ) : isLoadingTipos ? (
                          <MenuItem key="Carregando" value="Carregando">
                            Carregando...
                          </MenuItem>
                        ) : (
                          tipos &&
                          tipos.map((tipo) => (
                            <MenuItem key={tipo._id} value={tipo.nome}>
                              {tipo.nome}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  )}
                  {tipo && (
                    <FormControl size="small" sx={{ marginTop: "1rem" }}>
                      <InputLabel id="tag-label" shrink>
                        Tag
                      </InputLabel>
                      <Select
                        labelId="tag-label"
                        id="tag"
                        value={tag}
                        label="Tag"
                        displayEmpty
                        onChange={(e) => setTag(e.target.value)}
                        size="small"
                      >
                        <MenuItem key="vazio" value="">
                          Todos
                        </MenuItem>
                        {tags &&
                          tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                              {tag}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                  {tipo && opcoes(tipo)}
                </Grid>
                <Grid item>
                  <FormControl size="small" fullWidth>
                    <InputLabel id="tipoVinculo-label" shrink>
                      Tipo Vínculo
                    </InputLabel>
                    <Select
                      labelId="tipoVinculo-label"
                      label="Tipo Vínculo"
                      id="tipoVinculo"
                      value={tipoVinculo}
                      displayEmpty
                      fullWidth
                      onChange={(e) => handleChangeTipoVinculo(e.target.value)}
                    >
                      <MenuItem value="">Escolha um tipo de vínculo</MenuItem>
                      {isErrorTipos ? (
                        <MenuItem key="Erro" value="Erro">
                          Erro
                        </MenuItem>
                      ) : isLoadingTipos ? (
                        <MenuItem key="Carregando" value="Carregando">
                          Carregando...
                        </MenuItem>
                      ) : (
                        tipos &&
                        tipos.map(
                          (tipoV) =>
                            (tipoV.nome !== tipo ||
                              tipoV.nome === "Pessoa") && (
                              <MenuItem key={tipoV._id} value={tipoV.nome}>
                                {tipoV.nome}
                              </MenuItem>
                            )
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  {tipoVinculo !== "" && (
                    <VinculoAutocomplete
                      tipo={tipoVinculo}
                      setVinculo={handleChangeVinculo}
                      label=""
                    />
                  )}
                </Grid>
              </Grid>
            </form>
            {buscando && (
              <GraficoEstatisticas
                tipo={strTipo}
                params={{
                  searchText,
                  tag: stag,
                  nc: snc,
                  nnc: snnc,
                  obs: sobs,
                  nobs: snobs,
                  bl: sbl,
                  indicacao: sindicacao,
                  tipoVinculo: stipoVinculo
                    ? `${stipoVinculo.toLowerCase()}s`
                    : null,
                  vinculo: svinculo,
                }}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        style={{ paddingTop: "1.2rem" }}
        sx={{ minHeight: "50%" }}
      >
        <Card sx={{ opacity: "0.95" }}>
          <CardHeader
            avatar={<SearchIcon />}
            titleTypographyProps={{
              fontSize: "1em",
              fontWeight: 700,
              letterSpacing: "0",
            }}
            title={"Resultado Busca"}
          ></CardHeader>
          <CardContent>
            {buscando && (
              <Lista
                icone={<PersonSearchIcon />}
                titulo={`${tipo}s`}
                linkTitulo={`${strTipo}s`}
                itens={itens[`${strTipo}s`]}
                isError={isError}
                isLoading={isLoading}
                linkItem={`${strTipo}s`}
                height="calc(65vh)"
                checkBox={true}
              />
            )}
          </CardContent>
          {buscando && (
            <CardActions
              sx={{ justifyContent: "center", flexWrap: "wrap" }}
              disableSpacing
            >
              <Button
                variant="contained"
                sx={{ minWidth: "5.5rem", margin: "0.3rem" }}
                onClick={() => handleLimpar()}
              >
                Limpar
              </Button>
              <Tooltip
                title={`${
                  relCount > 0 ? relCount : itens[`${strTipo}s`].length
                } itens`}
              >
                <Button
                  variant="contained"
                  sx={{ minWidth: "5.5rem", margin: "0.3rem" }}
                  onClick={() => handleConfirmaGerar()}
                  onMouseOver={() => setRelCount(checkCount)}
                >
                  Gerar relatório
                </Button>
              </Tooltip>
            </CardActions>
          )}
        </Card>
      </Grid>

      <Dialog
        open={openConfirmaGerar}
        onClose={()=>handleCloseDialogGerar(false)}
        >
            <DialogTitle id="alert-dialog-title">
                Deseja gerar o Relatório?
            </DialogTitle>
            <DialogContent>
              <FormControl size="small" sx={{marginTop: "10px"}} fullWidth>
                <InputLabel id="tipoarquivo-label" shrink>
                  Tipo de arquivo
                </InputLabel>
                <Select
                  labelId="tipoarquivo-label"
                  label="Tipo de arquivo"
                  id="tipo"
                  value={tipoArquivo}
                  fullWidth
                  onChange={(e)=>setTipoArquivo(e.target.value)}
                >
                  {
                    tiposArquivo &&
                    tiposArquivo.map((tipo) => (
                      <MenuItem key={tipo} value={tipo}>
                        {tipo}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <FormControl size="small" fullWidth margin="dense" sx={{display: "block", flexDirection: "row", marginTop: "30px", padding: "10px"}}>

                <InputLabel id="tipoarquivo-label" shrink>
                  Campos a serem adicionados ao relatório
                </InputLabel>
              {campos.map(campo=>
                <FormControlLabel
                  key={campo}

                  control={
                    <Checkbox
                      name={campo}
                      checked={camposRel.some(item=> item === campo)}
                      onClick={(e) => setCamposRel(prev=> {
                        let campo = e.target.name;
                        
                        if(campo === "nome") return prev; //não deixa tirar nome

                        let newCampos = [...prev];
                        if(newCampos.some(item=> item === campo)) {
                          newCampos = newCampos.filter(item => item !== campo);
                        }
                        else {
                          newCampos.push(campo);
                        }
                        return newCampos;
                      })}
                    />
                  }
                  label={campoParaLegenda[campo]}
                />
                )}
          
                </FormControl>
                
            </DialogContent>	
            <DialogActions sx={{alignSelf: "center"}}>
                <Button onClick={()=>handleCloseDialogGerar(false)} variant="contained">Não</Button>
                <Button onClick={()=>handleGerar(true, tipoArquivo, camposRel)} variant="contained" disabled={camposRel.length < 1}>Sim</Button>
            </DialogActions>
        </Dialog>	

        <AlertaDialog
        open={alertaOpen}
        setOpen={setAlertaOpen}
        titulo="Alerta"
        msg={msgAlerta}
      />
    </PaginaBase>
  );
}
export default Busca;
