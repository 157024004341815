import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Pagination,
  CircularProgress,
  LinearProgress
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import PaginaBase from "../components/PaginaBase";
import Lista from "../components/Lista";
import ConfirmaDialog from "../components/ConfirmaDialog";
import AlertaDialog from "../components/AlertaDialog";
import { Link } from "react-router-dom";
import {
  useGetLastImportacoes,
  useGetImportacao,
  delImportacao,
} from "../services/importacaoService";

const campos = [
  "nome",
  "alcunhas",
  "tags",
  "nc",
  "bl",
  "mae",
  "cpf",
  "rg",
  "dn",
];

function Importacoes() {
  const { _id } = useParams();

  const [idImportacao, setIdImportacao] = useState(_id);

  const [confirmaOpen, setConfirmaOpen] = useState(false);
  const [msgConfirma, setMsgConfirma] = useState("");

  const [alertaOpen, setAlertaOpen] = useState(false);
  const [msgAlerta, setMsgAlerta] = useState("");

  const [deletando, setDeletando] = useState(false);

  const [pagina, setPagina] = useState(1);

  const {
    importacao,
    totalPages,
    total,
    pessoas,
    currentPage,
    isLoading: isLoadingImportacao,
    isError: isErrorLImportacao,
  } = useGetImportacao(idImportacao, pagina);

  const {
    importacoes: lastImportacoes,
    isLoading: isLoadingLImportacoes,
    isError: isErrorLImportacoes,
    mutate,
  } = useGetLastImportacoes();

  function montaLinhas() {
    if (pessoas?.length < 1) return;

    // setTotalPages(Math.ceil(pessoas?.length / itensPorPagina));

    let linhas = [];

    for (
      let i = 0; i < pessoas?.length;  i++) {
      linhas.push(
        <TableRow key={"r" + i}>
          {campos.map((key) => (
            <TableCell key={`${key}${i}`}>
              {key === "tags" && Array.isArray(pessoas[i]?.[key])
                ? pessoas[i]?.[key].join(" | ")
                : key === "dn" && pessoas[i]?.[key]
                ? new Date(pessoas[i]?.[key])
                    .toISOString()
                    .slice(0, 10)
                : pessoas[i]?.[key]?.toString()}
            </TableCell>
          ))}
        </TableRow>
      );
    }

    return linhas;
  }

  function confirmaDeletar() {
    setMsgConfirma(
      "Deseja cancelar essa importação?" +
        "<br>Obs: Todas as pessoas vinculadas serão deletadas!"
    );
    setConfirmaOpen(true);
  }

  function handleDeletar(confirma) {
    if (confirma) {
      setDeletando(true);
      delImportacao(importacao._id)
        .then(() => {
          setMsgAlerta(
            <Typography component="span">
              Importação id: {importacao._id} deletada!
            </Typography>
          );
          setAlertaOpen(true);
          mutate();
          setIdImportacao(null);
        })
        .catch((err) => {
          setMsgAlerta(
            <>
              <Typography component="span">
                Não foi possível deletar a importação id: {importacao._id}!
              </Typography>
              <br />
              <Typography component="span">Erro: {err.message}!</Typography>
            </>
          );
          setAlertaOpen(true);
        })
        .finally(()=>{
          setDeletando(false);
        });
    }
  }

  function handleClickImportacao(_id) {
    setPagina(1);
    setIdImportacao(_id);
  }

  return (
    <PaginaBase>
      <Grid
        item
        xs={12}
        md={2}
        style={{ paddingTop: "1.2rem", paddingLeft: "0px" }}
      >
        <Lista
          icone={<ImportExportIcon />}
          titulo={"Últimas importações"}
          itens={lastImportacoes}
          isError={isErrorLImportacoes}
          isLoading={isLoadingLImportacoes}
          linkItem={handleClickImportacao}
          height="calc(76vh - 1.5em)"
          sx={{ maxWidth: "2rem" }}
        />
      </Grid>

      <Grid item xs={12} md={9} sx={{height: "100%"}} style={{paddingTop: "1.2rem"}}>

      <Card
        sx={{
          maxHeight: pessoas ? "100%" : "40rem",
          maxWidth: "92%",
          opacity: "0.95",
          marginBottom: "20px",
          paddingBottom: "20px"
        }}
      >
        <CardHeader
          title={
            importacao
              ? new Date(importacao?.dtCriacao).toLocaleString() +
                " | " +
                (total && total) +
                ` pessoas - (S:${importacao?.sucessos || 0}|` +
                `D:${importacao?.duplicados || 0}|` +
                `E:${importacao?.erros || 0}|` +
                `T:${importacao?.total || 0}) | por: ` +
                importacao?.por?.nome
              : "Selecione uma importação"
          }
        />
        <CardContent
          sx={{ textAlign: "center", overflow: "auto", height: "calc(41vh - 1.5em)" }}
        >
          {Array.isArray(pessoas) &&
            !isLoadingImportacao &&
            !isErrorLImportacao ? (
              <Table>
                <TableHead>
                  <TableRow>
                    {campos.map((key) => (
                      <TableCell key={key} sx={{ fontWeight: 700 }}>
                        {key.toUpperCase()}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{montaLinhas()}</TableBody>
              </Table>
            ) : isLoadingImportacao ? (
              <div
                style={{
                  textAlign: "center",
                  height: "50%",
                  alignContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              (isErrorLImportacao || pessoas?.length < 1) && (
                <Typography
                  align="center"
                  sx={{ height: "50%", alignContent: "center" }}
                >
                  Sem Dados
                </Typography>
              )
            )}
        </CardContent>
        <CardActions sx={{paddingTop: "30px", justifyContent: "center"}}>
          {Array.isArray(pessoas) && (
            <>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(e, value) => setPagina(value)}
                sx={{ marginTop: "0.2rem", paddingBottom: "0.2rem" }}
              />
              <br />
            </>
          )}
          {idImportacao && Array.isArray(pessoas) ? (
            <>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                onClick={confirmaDeletar}
                disabled={deletando}
              >
                Deletar
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                LinkComponent={Link}
                to="../importar"
              >
                Cancelar
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              size="large"
              sx={{ margin: "0.7rem" }}
              LinkComponent={Link}
              to="../importar"
            >
              Cancelar
            </Button>
          )}
        </CardActions>
        <Grid sx={{ width: "100%", textAlign: "-webkit-center" }}>
        {deletando && (
            <LinearProgress
              variant="indeterminate"
              sx={{ height: 10, width: "90%", borderRadius: 5 }}
            />
        )}            
        </Grid>

      </Card>
      </Grid>
      <ConfirmaDialog
        open={confirmaOpen}
        setOpen={setConfirmaOpen}
        titulo="Alerta"
        msg={msgConfirma}
        handleConfirma={handleDeletar}
      />
      <AlertaDialog
        open={alertaOpen}
        setOpen={setAlertaOpen}
        titulo="Alerta"
        msg={msgAlerta}
      />
    </PaginaBase>
  );
}
export default Importacoes;
