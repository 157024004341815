import {useState, useContext, useEffect} from "react";
import { mutate as mutateKey } from "swr"
import { useParams } from "react-router-dom";
import PaginaBase from "../../global/components/PaginaBase";
import { useAuth } from "../../global/components/AuthProvider";
import Msg from "../../global/components/Msg";
import AddVinculoDialog from "../../global/components/AddVinculoDialog";
import { useGetLastEmpresas, useGetBuscaEmpresa, useGetEmpresa, postEmpresa, patchEmpresa, delEmpresa } from "../services/empresaService";
import { useGetTagsByNome } from "../../admin/services/tipoService";
import { validaCNPJ } from "../../global/helpers/validators";

import Lista from "../../global/components/Lista";
import { TextField, IconButton, Grid, Card, CardHeader, CardContent, CardActions, Button, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import ApartmentIcon from '@mui/icons-material/Apartment';
import SearchIcon from "@mui/icons-material/Search";
import InventoryIcon from '@mui/icons-material/Inventory';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';

import { AddCircle, Person } from "@mui/icons-material";
import {ColorModeContext} from "../../App";
import ConfirmaDialog from "../../global/components/ConfirmaDialog";

const EmpresaApiUrl = process.env.REACT_APP_API_URL + "/empresa";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Empresas() {
  const colorMode = useContext(ColorModeContext);
  const auth = useAuth();

  const { id } = useParams();

  const [inputText, setinputText] = useState("");
  const [tag, setTag] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [searchTag, setSearchTag] = useState(null);
  const [nc, setNc] = useState(null);
  const [nnc, setNnc] = useState(null);
  const [bl, setBl] = useState(null);
  const [obs, setObs] = useState(null);
  const [nobs, setNObs] = useState(null);
  const [indicacao, setIndicacao] = useState(null);

  const [resEmpresa, setResEmpresa] = useState(null)
  const [editado, setEditado] = useState(false);
  const [msg, setMsg] = useState("");
  const [idEmpresa, setIdEmpresa] = useState(id || null)

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [tipoVinculo, setTipoVinculo] = useState(null);

  const [confirmaMsg, setConfirmaMsg] = useState("");
  const [confirmaOpen, setConfirmaOpen] = useState(false);
  
  const [page, setPage] = useState(1);
  const { empresas: lastEmpresas, isLoading: isLoadingLP, isError: isErrorLP } = useGetLastEmpresas(page);
  const { empresas: buscaEmpresas, isLoading: isLoadingBP, isError: isErrorBP } = useGetBuscaEmpresa(searchText, searchTag, page, null, null, nc, nnc, bl, obs, nobs, indicacao, false);
  const { empresa: empresaID, isLoading: isLoadingID, isError: isErrorID, mutate } = useGetEmpresa(idEmpresa);
  const { tags, isLoading: isLoadingTags, isError: isErrorTags } = useGetTagsByNome("Empresa");

  const [empresa, setEmpresa] = useState(id ? empresaID : {tags:[]});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  // const [titEmpresa, setTitEmpresa] = useState(id ? empresaID?.nome : null);

  // console.log(addDialogOpen);

  // const [tag, setTipo] = useState([]);

  const empresaEditTag = (event) => {
    setEditado(true);
    if(resEmpresa === null) {
      setResEmpresa(empresa);
    }
    // console.log(tag);
    const {
      target: { value },
    } = event;
    // setTipo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setEmpresa(prevState => ({
      ...prevState,
      "tags": typeof value === 'string' ? value.split(',') : value
    })); 
  };

  function handleEmpresa(id) {
    setMsg("");
    setIsError(false);
    setEditado(false);
    setResEmpresa(null);
    setIdEmpresa(id);
  }

  function handlePaste(e) { // somente implementados para cpf e rg função de colar
    e.preventDefault();
    let { name } = e.target;

    let paste = e.clipboardData.getData("text");
    paste = paste.replace(/\D/g, "");

    if(name === "cnpj") paste = paste.slice(0, 14);
    else if (name === "telefone") paste = paste.slice(0, 12);

    setEmpresa(prevState => ({
      ...prevState,
      [name]: paste
    }));

  }

  function empresaEdit(e){
    setEditado(true);
    if(resEmpresa === null) {
      setResEmpresa(empresa);
    }
    
    let { name, value } = e.target;
    // console.log(empresa);
    // console.log(name, value)
    if(name === "cnpj" || name === "telefone") {
      value = value.replace(/\D/g, "");
    }

    setEmpresa(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  function novoEmpresa(){
    setEditado(false);
    setResEmpresa(null);
    setIdEmpresa(null);
    setEmpresa({});
  }

  function limparEmpresa(){
    setEditado(idEmpresa ? true : false);
    setEmpresa({_id: idEmpresa});
    // console.log(editado, empresa?.nome);
  }

  function resetEmpresa(){
    if(resEmpresa !== null){
      setEditado(false);
      setEmpresa(resEmpresa);
    }
  }

  function salvaEmpresa() {
    setIsError(false);
    setIsloading(true);
    setMsg("Salvando...");

    if(empresa?.cnpj && empresa?.cnpj !== "" && !validaCNPJ(empresa?.cnpj)) {
      setIsError(true);
      setMsg("CNPJ inválido");
      setIsloading(false);
      return;
    }

    let atualizado = idEmpresa ? patchEmpresa : postEmpresa;
    atualizado(empresa)
      .then((data) => {setMsg(data.message);
                        mutateKey(key => typeof key === 'string' && key.startsWith(`${EmpresaApiUrl}s/last`));
                        mutateKey(key => typeof key === 'string' && key.startsWith(`${EmpresaApiUrl}s/busca`));
                        mutate();
                        setEditado(false);
                        setResEmpresa(null);
                        // setEmpresa({});
                        setIdEmpresa(data?._id);
                        })
                        .catch((error) => {
                            setIsError(true)
                            const id = error?.message?.match(/\(([^)]+)\)/);
                            if(id) {
                              setMsg(<Tooltip title="Ver empresa"><span onClick={()=>handleEmpresa(id[1])} style={{cursor: "pointer"}}>{error.message}</span></Tooltip>)                               
                            }
                            else {
                              setMsg(error.message)                               
                            }
                        })
                        .finally(()=>{
                          setIsloading(false);
                          // setMsg("");
                        });  
    }

  const handleSearchChange = (e) => {
    let lowerCase = e.target.value.toLowerCase().replace(/[^a-zA-Z0-9\-./ ]/g, "");
    setinputText(lowerCase);
  };
  
  function handleSearchCheck(e) {
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    if(e.target.name === "nc") {
      setNc(e.target.checked);
      if(e.target.checked){
        setBl(!e.target.checked)
        setNnc(!e.target.checked)
      }
    }
    else if(e.target.name === "bl") {
      setBl(e.target.checked);
      if(e.target.checked) setNc(!e.target.checked);
    }
    else if(e.target.name === "nnc") {
      setNnc(e.target.checked);
      if(e.target.checked) setNc(!e.target.checked);
    }    
  }

  function handleSearchObsCheck(e) {
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    if(e.target.name === "obs") {
      setObs(e.target.checked);
      if(e.target.checked) setNObs(!e.target.checked);
    }
    else if(e.target.name === "nobs") {
      setNObs(e.target.checked);
      if(e.target.checked) setObs(!e.target.checked);
    }
    else if(e.target.name === "indicacao") {
      setIndicacao(e.target.checked);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    //alert(searchText);
  };

  function handleCheck(e) {
    setEditado(true);
    let outro = null;

    if(e.target.checked) { //só pode 1 checado
      if(e.target.name === "nc") outro = "bl";
      else outro = "nc";
    }
    
    setEmpresa(prev=> ({...prev, [e.target.name]: e.target.checked, [outro]: !e.target.checked}));
    // const checks = ["nc", "bl"];
  }

  function handleOpenAddDialog (tipo) {
    // console.log(empresa);
    setAddDialogOpen(true);
    setTipoVinculo(tipo);
  }

  function handleDelete() {
    setConfirmaMsg("Deseja deletar a empresa?");
    setConfirmaOpen(true);
  }

  function handleConfirmaDelete(confirma) {
    setIsloading(true);
    setIsError(false);
    setMsg("Deletando...");
    if(confirma){
      delEmpresa(empresa)
        .then((data) => {setMsg(data.message);
                          setIdEmpresa(null);
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${EmpresaApiUrl}s/last`));
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${EmpresaApiUrl}s/busca`));
                          // mutate();
                          setEditado(false);
                          setResEmpresa(null);
                          // setEmpresa({});
                          // setTitEmpresa(null);
                          setIdEmpresa(null);
                          })
                          .catch((error) => {
                              setIsError(true)
                              setMsg(error.message)
                          })
                          .finally(()=> {
                            setIsloading(false);
                          });        
      }
  }

  function isBusca() {
    return (searchText || searchTag || nc || nnc || bl || obs || nobs || indicacao)
  }

  useEffect(() => {
    // console.log(editado, empresa?.nome);
    setEmpresa(empresaID); 
    setResEmpresa(empresaID); 
    // setTitEmpresa(empresaID?.nome || null);
},[empresaID]);

  return (
      <PaginaBase>
        <Grid item xs={12} md={4} style={{paddingTop: "1.2rem", paddingLeft: "0px"}}>
          <Card sx={{marginBottom: "1.5rem", opacity: "0.95"}} elevation={10}>
            <CardHeader avatar={<SearchIcon/>} title="Busca" titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}}/>
            <CardContent>
            <form>
              <TextField
                id="searchbar"
                label="Digite nome ou algum dado da empresa"
                variant="outlined"
                placeholder="Busca..."
                size="small"
                sx={{width: 'calc(100% - 50px)', marginLeft: '0.5rem'}}
                onChange={handleSearchChange}
                value={inputText}
                inputProps={{ type: 'search'}}
              />
              <Tooltip title="Buscar">
              <IconButton aria-label="search" onClick={handleClick} type="submit">
                <SearchIcon color={colorMode.mode === "light" ? "primary" : ""}/>
              </IconButton>
              </Tooltip>
              <FormControl size="small" sx={{width: 'calc(100% - 50px)', marginTop: "0.5rem",marginLeft: '0.5rem'}}>
        <InputLabel id="tag-label" shrink>Tag</InputLabel>
        <Select
          labelId="tag-label"
          id="tag"
          value={tag}
          label="Tipo"
          displayEmpty
          onChange={(e) => setTag(e.target.value)}
        ><MenuItem key="vazio" value="">Todos</MenuItem>
          {isErrorTags ? <MenuItem key="Erro" value="Erro">Erro</MenuItem> :
          isLoadingTags ? <MenuItem key="Carregando" value="Carregando">Carregando...</MenuItem> :
           tags && tags.map((tag) => <MenuItem key={tag} value={tag}>{tag}</MenuItem>)}

        </Select>
      </FormControl>      
      <Tooltip title="Marcado como Nada Consta">
                    <FormControlLabel
                      control={
                        <Checkbox name="nc" color="success" checked={nc || false} onClick={(e)=>handleSearchCheck(e)}/>
                      }
                      label="NC"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Não marcado como Nada Consta">
                    <FormControlLabel
                      control={
                        <Checkbox name="nnc" color="success" checked={nnc || false} onClick={(e)=>handleSearchCheck(e)}/>
                      }
                      label="Sem NC"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Marcado como Entrevista">
                    <FormControlLabel
                      control={
                        <Checkbox name="bl" color="error" checked={bl || false} onClick={(e)=>handleSearchCheck(e)}/>
                      }
                      label="Entrevista"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Somente com observação">
                    <FormControlLabel
                      control={
                        <Checkbox name="obs" color="info" checked={obs || false} onClick={(e)=>handleSearchObsCheck(e)}/>
                      }
                      label="Observação"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Somente sem observação">
                    <FormControlLabel
                      control={
                        <Checkbox name="nobs" color="info" checked={nobs || false} onClick={(e)=>handleSearchObsCheck(e)}/>
                      }
                      label="Sem Observação"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Somente com indicação">
                    <FormControlLabel
                      control={
                        <Checkbox name="indicacao" color="success" checked={indicacao || false} onClick={(e)=>handleSearchObsCheck(e)}/>
                      }
                      label="Com indicação"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
            </form>
            </CardContent>            
          </Card>
        <Lista icone={<ApartmentIcon/>} titulo={isBusca() ? "Empresas encontradas" : "Últimas empresas"} linkTitulo={null} 
          itens={isBusca() ? buscaEmpresas?.empresas : lastEmpresas?.empresas} currentPage={page} setPage={setPage}
          totalPages={isBusca() ? buscaEmpresas?.totalPages : lastEmpresas?.totalPages}
          isError={isBusca() ? isErrorBP : isErrorLP} isLoading={isBusca() ? isLoadingBP : isLoadingLP} linkItem={handleEmpresa} height="calc(50vh - 0.5rem)"/>
        </Grid>
        <Grid item xs={12} md={8} style={{paddingTop: "1.2rem"}} sx={{minHeight: "50%"}}>
          <Card sx={{opacity: "0.95"}}>
            <CardHeader avatar={<ApartmentIcon/>} titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}} title={idEmpresa === null ? "Nova Empresa" : empresa?.nome}></CardHeader>
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item flexGrow={1}>
                    <TextField value={empresa?.nome || ""} onChange={(e)=>empresaEdit(e)} name="nome" id="nome" label="Nome" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="tags">tags</InputLabel>
                      <Select
                        labelId="tags-label"
                        id="tags"
                        multiple
                        value={empresa?.tags || []}
                        onChange={empresaEditTag}
                        input={<OutlinedInput id="select-tags" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {tags && tags.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={{fontWeight: empresa?.tags?.indexOf(name) === -1 ? "" : "bold"}}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>                
                  </Grid>
                  <Grid item flexGrow={1} sx={{textAlign: "center"}}>
                    <FormControlLabel
                      control={
                        <Checkbox name="nc" color="success" checked={empresa?.nc || false} onClick={(e)=>handleCheck(e)}/>
                      }
                      label="NC"
                    />
                  </Grid>
                  <Grid item flexGrow={1} sx={{textAlign: "center"}}>
                    <FormControlLabel
                      control={
                        <Checkbox name="bl" color="error" checked={empresa?.bl || false} onClick={(e)=>handleCheck(e)}/>
                      }
                      label="Entrevista"
                    />
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={empresa?.cnpj || ""} autoComplete="off" onPaste={(e)=> handlePaste(e)} onChange={(e)=>empresaEdit(e)} name="cnpj" id="cnpj" label="CNPJ" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{maxLength:14}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={empresa?.telefone || ""} autoComplete="off" onPaste={(e)=> handlePaste(e)} onChange={(e)=>empresaEdit(e)} name="telefone" id="telefone" label="Telefone" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{maxLength:12}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={empresa?.obs || ""} onChange={(e)=>empresaEdit(e)} name="obs" id="obs" label="Observações" variant="outlined" fullWidth size="small" multiline rows={3} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={empresa?.indicacao || ""} onChange={(e)=>empresaEdit(e)} name="indicacao" id="indicacao" label="Indicação" variant="outlined" fullWidth size="small" multiline rows={3} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={empresa?.por?.nome || ""} name="por" id="por" label="Alterado por" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={empresa?.dtAlteracao ? new Date(empresa?.dtAlteracao).toLocaleString() : ""} name="dtAlteracao" id="dtAlteracao" label="Alterado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={empresa?.dtCriacao ? new Date(empresa?.dtCriacao).toLocaleString() : ""} name="dtCriacao" id="dtCriacao" label="Criado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                </Grid>
              </form>
              <Msg msg={isLoadingID ? "Carregando..." : isErrorID ? "Erro na obtenção dos dados" : msg} tipo={(isError || isErrorID) ? "error" : (isLoadingID || isLoading) ? "info" : "success" } onClose={()=>setMsg("")}></Msg>
            </CardContent>
            {auth.isEscrita() && <CardActions sx={{justifyContent: "center", flexWrap: "wrap"}} disableSpacing>
              <Button variant="contained" onClick={()=>novoEmpresa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Novo</Button>
              <Button variant="contained" disabled={isLoadingID} onClick={()=>limparEmpresa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Limpar</Button>
              <Button variant="contained" disabled={!editado} onClick={()=>resetEmpresa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Reset</Button>
              <Button variant="contained" disabled={(isLoading || !editado || (empresa?.nome === "" || empresa?.nome === null || empresa?.nome === undefined))} onClick={()=>salvaEmpresa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Salvar</Button>
              <Button variant="contained" disabled={isLoading || isLoadingID || (idEmpresa === null)} onClick={()=>handleDelete()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Deletar</Button>
            </CardActions>}

          </Card>
          <Grid container spacing={4} sx={{marginTop: "1px"}}>
            <Lista icone={<Person/>} titulo={"Pessoas vinculadas"} linkTitulo="pessoas" itens={empresa?.pessoas} linkItem="pessoas" height={null} btnSecundario={empresa?._id ? <AddCircle/> : null} btnSecundarioFn={empresa?._id ? ()=> handleOpenAddDialog("pessoa") : null}/>
            <Lista icone={<InventoryIcon/>} titulo={"Casos vinculados"} linkTitulo="casos" itens={empresa?.casos} linkItem="casos" height={null} btnSecundario={empresa?._id ? <AddCircle/> : null} btnSecundarioFn={empresa?._id ? ()=> handleOpenAddDialog("caso") : null}/>
            <Lista icone={<WorkIcon/>} titulo="Clientes vinculados" linkTitulo="clientes" itens={empresa?.clientes} linkItem="clientes" height={null} btnSecundario={empresa?._id ? <AddCircle/> : null} btnSecundarioFn={empresa?._id ? ()=> handleOpenAddDialog("cliente") : null}/>
            <Lista icone={<AssignmentIcon/>} titulo="Documentos vinculados" linkTitulo="documentos" itens={empresa?.documentos} linkItem="documentos" height={null} btnSecundario={empresa?._id ? <AddCircle/> : null} btnSecundarioFn={empresa?._id ? ()=> handleOpenAddDialog("documento") : null}/>
          </Grid>
        </Grid>
        {tipoVinculo && <AddVinculoDialog open={addDialogOpen} setOpen={setAddDialogOpen} obj={empresa} setObj={setEmpresa} tipo={tipoVinculo} setEditado={setEditado}/>}
        <ConfirmaDialog open={confirmaOpen} setOpen={setConfirmaOpen} msg={confirmaMsg} handleConfirma={handleConfirmaDelete}/>
      </PaginaBase>
    );
  }
  export default Empresas;