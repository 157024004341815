
import { Button, Card, CardContent, CardHeader, TextField } from "@mui/material";
import PaginaBase from "../components/PaginaBase";
import Msg from "../components/Msg";
import { useRef, useState } from "react";
import { useAuth } from "../components/AuthProvider";


function Login() {

  const username = useRef("");
  const pwd = useRef("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [msg, setMsg] = useState("");
  // const navigate = useNavigate();
  const auth = useAuth();

  async function handleEntrar(e) {
    e.preventDefault();
    // console.log(username.current?.value, pwd.current?.value);
    if (username.current?.value === "" || pwd.current?.value === "") {
      setIsError(true);
      setMsg("Preencha os campos corretamente!");
    } else {
      setLoading(true);
      setIsError(false);
      setMsg("Entrando...");
      auth.logIn(username.current.value, pwd.current.value)
      .then((data)=> (data && auth.user))
      .catch((err)=>{
        setIsError(true);
        setMsg(err.message)
      })
      .finally(() => {
        setLoading(false)
      });
      // console.log(data);
    }
  }

    return (
      <PaginaBase>
        <Card sx={{maxHeight: "24rem", marginTop:"3rem", maxWidth: "92%", opacity: "0.95"}}>
          <CardHeader title="Login"/>
          <CardContent sx={{textAlign: "center"}}>
            <form>
            <TextField type="text" name="username" inputRef={username} label="Usuário" fullWidth sx={{marginTop:"0.4rem", marginBottom: "0.4rem"}} required error={isError}/>
            <TextField type="password" name="pwd" inputRef={pwd} label="Senha" fullWidth sx={{marginTop:"0.4rem", marginBottom: "0.4rem"}} required error={isError}/>
            <Msg msg={msg} tipo={(isError) ? "error" : loading ? "info" : "success" } onClose={()=>setMsg("")} block></Msg>
            <Button variant="contained" size="large" sx={{marginTop:"20px"}} type="submit" onClick={(e)=>handleEntrar(e)} disabled={loading}>Entrar</Button>
            </form>
          </CardContent>
        </Card>
      </PaginaBase>
    );
  }
  export default Login;