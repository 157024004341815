import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DialogContent } from '@mui/material';

function AlertaDialog({titulo = "Alerta", msg, open, setOpen}) {

	function handleCloseDialog() {
        setOpen(false);
	}

    return (
        <Dialog
        open={open}
        onClose={()=>handleCloseDialog(false)}
        >
            <DialogTitle id="alert-dialog-title">
                {titulo}
            </DialogTitle>
            <DialogContent sx={{justifyContent: (msg.length > 1) ? "left" : "center"}}>
                {msg}
            </DialogContent>	
            <DialogActions sx={{alignSelf: "center"}}>
                <Button onClick={()=>handleCloseDialog()} variant="contained">Fechar</Button>
            </DialogActions>
        </Dialog>	
    )
}

export default AlertaDialog;