import {useState, useContext, useEffect} from "react";
import { mutate as mutateKey } from "swr"
import { Link, useParams } from "react-router-dom";
import PaginaBase from "../../global/components/PaginaBase";
import { useAuth } from "../../global/components/AuthProvider";
import Msg from "../../global/components/Msg";
import AddVinculoDialog from "../../global/components/AddVinculoDialog";
import { useGetLastPessoas, useGetBuscaPessoa, useGetPessoa, postPessoa, patchPessoa, delPessoa } from "../services/pessoaService";
import { useGetTagsByNome } from "../../admin/services/tipoService";
import { validaCPF } from "../../global/helpers/validators";

import Lista from "../../global/components/Lista";
import { TextField, IconButton, Grid, Card, CardHeader, CardContent, CardActions, Button, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import SearchIcon from "@mui/icons-material/Search";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import InventoryIcon from '@mui/icons-material/Inventory';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';

import { AddCircle, Person } from "@mui/icons-material";
import {ColorModeContext} from "../../App";
import ConfirmaDialog from "../../global/components/ConfirmaDialog";

const PessoaApiUrl = process.env.REACT_APP_API_URL + "/pessoa";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Pessoas() {
  const colorMode = useContext(ColorModeContext);
  const auth = useAuth();

  const { id } = useParams();

  const [inputText, setinputText] = useState("");
  const [tag, setTag] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [searchTag, setSearchTag] = useState(null);
  const [nc, setNc] = useState(null);
  const [nnc, setNnc] = useState(null);
  const [bl, setBl] = useState(null);
  const [obs, setObs] = useState(null);
  const [nobs, setNObs] = useState(null);
  const [indicacao, setIndicacao] = useState(null);

  const [resPessoa, setResPessoa] = useState(null)
  const [editado, setEditado] = useState(false);
  const [msg, setMsg] = useState("");
  const [idPessoa, setIdPessoa] = useState(id || null)

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [tipoVinculo, setTipoVinculo] = useState(null);

  const [confirmaMsg, setConfirmaMsg] = useState("");
  const [confirmaOpen, setConfirmaOpen] = useState(false);
  
  const [page, setPage] = useState(1);
  const { pessoas: lastPessoas, isLoading: isLoadingLP, isError: isErrorLP } = useGetLastPessoas(page);
  const { pessoas: buscaPessoas, isLoading: isLoadingBP, isError: isErrorBP } = useGetBuscaPessoa(searchText, searchTag, page, null, null, nc, nnc, bl, obs, nobs, indicacao, false);
  const { pessoa: pessoaID, isLoading: isLoadingID, isError: isErrorID, mutate } = useGetPessoa(idPessoa);
  const { tags, isLoading: isLoadingTags, isError: isErrorTags } = useGetTagsByNome("Pessoa");

  const [pessoa, setPessoa] = useState(id ? pessoaID : {tags:[]});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  // const [titPessoa, setTitPessoa] = useState(id ? pessoaID?.nome : null);

  // console.log(addDialogOpen);

  // const [tag, setTipo] = useState([]);

  const pessoaEditTag = (event) => {
    setEditado(true);
    if(resPessoa === null) {
      setResPessoa(pessoa);
    }
    // console.log(tag);
    const {
      target: { value },
    } = event;
    // setTipo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setPessoa(prevState => ({
      ...prevState,
      "tags": typeof value === 'string' ? value.split(',') : value
    })); 
  };

  function handlePessoa(id) {
    setMsg("");
    setIsError(false);
    setEditado(false);
    setResPessoa(null);
    setIdPessoa(id);
  }

  function handlePaste(e) { // somente implementados para cpf e rg função de colar
    e.preventDefault();
    let { name } = e.target;

    let paste = e.clipboardData.getData("text");
    paste = paste.replace(/\D/g, "");

    if(name === "cpf") paste = paste.slice(0, 11);
    else if (name === "rg") paste = paste.slice(0, 20);

    setPessoa(prevState => ({
      ...prevState,
      [name]: paste
    }));

  }

  function pessoaEdit(e){
    setEditado(true);
    if(resPessoa === null) {
      setResPessoa(pessoa);
    }
    
    let { name, value } = e.target;
    // console.log(pessoa);
    // console.log(name, value)
    if(name === "cpf" || name === "rg") {
      value = value.replace(/\D/g, "");
    }

    setPessoa(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  function novoPessoa(){
    setEditado(false);
    setResPessoa(null);
    setIdPessoa(null);
    setPessoa({});
  }

  function limparPessoa(){
    setEditado(idPessoa ? true : false);
    setPessoa({_id: idPessoa});
    // console.log(editado, pessoa?.nome);
  }

  function resetPessoa(){
    if(resPessoa !== null){
      setEditado(false);
      setPessoa(resPessoa);
    }
  }

  function salvaPessoa() {
    setIsError(false);
    setIsloading(true);
    setMsg("Salvando...");

    if(pessoa?.cpf && pessoa?.cpf !== "" && !validaCPF(pessoa?.cpf)) {
      setIsError(true);
      setMsg("CPF inválido");
      setIsloading(false);
      return;
    }

    let atualizado = idPessoa ? patchPessoa : postPessoa;
    atualizado(pessoa)
      .then((data) => {setMsg(data.message);
                        mutateKey(key => typeof key === 'string' && key.startsWith(`${PessoaApiUrl}s/last`));
                        mutateKey(key => typeof key === 'string' && key.startsWith(`${PessoaApiUrl}s/busca`));
                        mutate();
                        setEditado(false);
                        setResPessoa(null);
                        // setPessoa({});
                        setIdPessoa(data?._id);
                        })
                        .catch((error) => {
                            setIsError(true)
                            // console.table(error);
                            const id = error?.message?.match(/\(([^)]+)\)/);
                            if(id) {
                              setMsg(<Tooltip title="Ver pessoa"><span onClick={()=>handlePessoa(id[1])} style={{cursor: "pointer"}}>{error.message}</span></Tooltip>)                               
                            }
                            else {
                              setMsg(error.message)                               
                            }
                        })
                        .finally(()=>{
                          setIsloading(false);
                          // setMsg("");
                        });  
  }

  const handleSearchChange = (e) => {
    let lowerCase = e.target.value.toLowerCase().replace(/[^a-zA-Z0-9\-./ ]/g, "");
    setinputText(lowerCase);
  };
 
  function handleSearchCheck(e) {
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    if(e.target.name === "nc") {
      setNc(e.target.checked);
      if(e.target.checked){
        setBl(!e.target.checked)
        setNnc(!e.target.checked)
      }
    }
    else if(e.target.name === "bl") {
      setBl(e.target.checked);
      if(e.target.checked) setNc(!e.target.checked);
    }
    else if(e.target.name === "nnc") {
      setNnc(e.target.checked);
      if(e.target.checked) setNc(!e.target.checked);
    }
  }

  function handleSearchObsCheck(e) {
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    if(e.target.name === "obs") {
      setObs(e.target.checked);
      if(e.target.checked) setNObs(!e.target.checked);
    }
    else if(e.target.name === "nobs") {
      setNObs(e.target.checked);
      if(e.target.checked) setObs(!e.target.checked);
    }
    else if(e.target.name === "indicacao") {
      setIndicacao(e.target.checked);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    //alert(searchText);
  };

  function handleCheck(e) {
    setEditado(true);
    let outro = null;

    if(e.target.checked) { //só pode 1 checado
      if(e.target.name === "nc") outro = "bl";
      else outro = "nc";
    }
    
    setPessoa(prev=> ({...prev, [e.target.name]: e.target.checked, [outro]: !e.target.checked}));
    // const checks = ["nc", "bl"];
  }

  function handleOpenAddDialog (tipo) {
    // console.log(pessoa);
    setAddDialogOpen(true);
    setTipoVinculo(tipo);
  }

  function handleDelete() {
    setConfirmaMsg("Deseja deletar a pessoa?");
    setConfirmaOpen(true);
  }

  function handleConfirmaDelete(confirma) {
    if(confirma){
      setIsloading(true);
      setIsError(false);
      setMsg("Deletando...");
        delPessoa(pessoa)
        .then((data) => {setMsg(data.message);
                          setIdPessoa(null);
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${PessoaApiUrl}s/last`));
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${PessoaApiUrl}s/busca`));
                          // mutate();
                          setEditado(false);
                          setResPessoa(null);
                          // setPessoa({});
                          // setTitPessoa(null);
                          setIdPessoa(null);
                          })
                          .catch((error) => {
                              setIsError(true);
                              setMsg(error.message);
                          })
                          .finally(()=> {
                            setIsloading(false);
                          });        
    }
  }

  function isBusca() {
    return (searchText?.trim()?.length > 3 || searchTag || nc || nnc || bl || obs || nobs || indicacao)
  }

  useEffect(() => {
    // console.log(editado, pessoa?.nome);
    setPessoa(pessoaID); 
    setResPessoa(pessoaID); 
    // setTitPessoa(pessoaID?.nome || null);
},[pessoaID]);

  return (
      <PaginaBase>
        <Grid item xs={12} md={4} style={{paddingTop: "1.2rem", paddingLeft: "0px"}}>
          <Card sx={{marginBottom: "1.5rem", opacity: "0.95"}} elevation={10}>
            <CardHeader avatar={<SearchIcon/>} title="Busca" titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}}/>
            <CardContent>
            <form>
              <TextField
                id="searchbar"
                label="Digite nome ou algum dado da pessoa"
                variant="outlined"
                placeholder="Busca..."
                size="small"
                sx={{width: 'calc(100% - 50px)', marginLeft: '0.5rem'}}
                onChange={handleSearchChange}
                value={inputText}
                inputProps={{ type: 'search'}}
                error={inputText?.trim()?.length < 4 && inputText?.trim()?.length > 0 }
                helperText="Mínimo de 4 caracteres para busca"
              />
              <Tooltip title="Buscar">
              <IconButton aria-label="search" onClick={handleClick} type="submit">
                <SearchIcon color={colorMode.mode === "light" ? "primary" : ""}/>
              </IconButton>
              </Tooltip>
              <FormControl size="small" sx={{width: 'calc(100% - 50px)', marginTop: "0.5rem",marginLeft: '0.5rem'}}>
        <InputLabel id="tag-label" shrink>Tag</InputLabel>
        <Select
          labelId="tag-label"
          id="tag"
          value={tag}
          label="Tipo"
          displayEmpty
          onChange={(e) => setTag(e.target.value)}
        ><MenuItem key="vazio" value="">Todos</MenuItem>
          {isErrorTags ? <MenuItem key="Erro" value="Erro">Erro</MenuItem> :
          isLoadingTags ? <MenuItem key="Carregando" value="Carregando">Carregando...</MenuItem> :
           tags && tags.map((tag) => <MenuItem key={tag} value={tag}>{tag}</MenuItem>)}

        </Select>
      </FormControl>      
                    <Tooltip title="Marcado como Nada Consta">
                    <FormControlLabel
                      control={
                        <Checkbox name="nc" color="success" checked={nc || false} onClick={(e)=>handleSearchCheck(e)}/>
                      }
                      label="NC"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Não marcado como Nada Consta">
                    <FormControlLabel
                      control={
                        <Checkbox name="nnc" color="success" checked={nnc || false} onClick={(e)=>handleSearchCheck(e)}/>
                      }
                      label="Sem NC"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Marcado como Entrevista">
                    <FormControlLabel
                      control={
                        <Checkbox name="bl" color="error" checked={bl || false} onClick={(e)=>handleSearchCheck(e)}/>
                      }
                      label="Entrevista"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Somente com observação">
                    <FormControlLabel
                      control={
                        <Checkbox name="obs" color="info" checked={obs || false} onClick={(e)=>handleSearchObsCheck(e)}/>
                      }
                      label="Observação"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Somente sem observação">
                    <FormControlLabel
                      control={
                        <Checkbox name="nobs" color="info" checked={nobs || false} onClick={(e)=>handleSearchObsCheck(e)}/>
                      }
                      label="Sem Observação"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
                    <Tooltip title="Somente com indicação">
                    <FormControlLabel
                      control={
                        <Checkbox name="indicacao" color="success" checked={indicacao || false} onClick={(e)=>handleSearchObsCheck(e)}/>
                      }
                      label="Com indicação"
                      sx={{marginLeft: '0.5rem'}}
                    />
                    </Tooltip>
            </form>
            </CardContent>            
          </Card>
        <Lista icone={isBusca() ? <PersonSearchIcon/> : <PersonAddIcon/>} titulo={isBusca() ? "Pessoas encontradas" : "Últimas pessoas"} linkTitulo={null} 
          itens={isBusca() ? buscaPessoas?.pessoas : lastPessoas?.pessoas} currentPage={page} setPage={setPage}
          totalPages={isBusca() ? buscaPessoas?.totalPages : lastPessoas?.totalPages}
          isError={isBusca() ? isErrorBP : isErrorLP} isLoading={isBusca() ? isLoadingBP : isLoadingLP} linkItem={handlePessoa} height="calc(50vh - 0.5rem)"/>
        </Grid>
        <Grid item xs={12} md={8} style={{paddingTop: "1.2rem"}} sx={{minHeight: "50%"}}>
          <Card sx={{opacity: "0.95"}}>
            <CardHeader avatar={<Person/>} titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}} title={idPessoa === null ? "Nova Pessoa" : pessoa?.nome}></CardHeader>
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.nome || ""} onChange={(e)=>pessoaEdit(e)} name="nome" id="nome" label="Nome" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.alcunhas || ""} onChange={(e)=>pessoaEdit(e)} name="alcunhas" id="alcunhas" label="Alcunhas" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="tags">tags</InputLabel>
                      <Select
                        labelId="tags-label"
                        id="tags"
                        multiple
                        value={pessoa?.tags || []}
                        onChange={pessoaEditTag}
                        input={<OutlinedInput id="select-tags" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {tags && tags.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={{fontWeight: pessoa?.tags?.indexOf(name) === -1 ? "" : "bold"}}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>                
                  </Grid>
                  <Grid item flexGrow={1} sx={{textAlign: "center"}}>
                    <FormControlLabel
                      control={
                        <Checkbox name="nc" color="success" checked={pessoa?.nc || false} onClick={(e)=>handleCheck(e)}/>
                      }
                      label="NC"
                    />
                  </Grid>
                  <Grid item flexGrow={1} sx={{textAlign: "center"}}>
                    <FormControlLabel
                      control={
                        <Checkbox name="bl" color="error" checked={pessoa?.bl || false} onClick={(e)=>handleCheck(e)}/>
                      }
                      label="Entrevista"
                    />
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.mae || ""} onChange={(e)=>pessoaEdit(e)} name="mae" id="mae" label="Mãe" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.cpf || ""} autoComplete="off" onPaste={(e)=> handlePaste(e)} onChange={(e)=>pessoaEdit(e)} name="cpf" id="cpf" label="CPF" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{maxLength:11}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.rg || ""} autoComplete="off" onPaste={(e)=> handlePaste(e)} onChange={(e)=>pessoaEdit(e)} name="rg" id="rg" label="RG" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{maxLength:20}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.dn ? new Date(pessoa.dn).toISOString().slice(0, 10): ""} onChange={(e)=>pessoaEdit(e)} name="dn" id="dn" type="date" label="Nascimento" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={pessoa?.obs || ""} onChange={(e)=>pessoaEdit(e)} name="obs" id="obs" label="Observações" variant="outlined" fullWidth size="small" multiline rows={4} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={pessoa?.indicacao || ""} onChange={(e)=>pessoaEdit(e)} name="indicacao" id="indicacao" label="Indicação" variant="outlined" fullWidth size="small" multiline rows={3} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.por?.nome || ""} name="por" id="por" label="Alterado por" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.dtAlteracao ? new Date(pessoa?.dtAlteracao).toLocaleString() : ""} name="dtAlteracao" id="dtAlteracao" label="Alterado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={pessoa?.dtCriacao ? new Date(pessoa?.dtCriacao).toLocaleString() : ""} name="dtCriacao" id="dtCriacao" label="Criado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                </Grid>
              </form>
              <Msg msg={isLoadingID ? "Carregando..." : isErrorID ? "Erro na obtenção dos dados" : msg} tipo={(isError || isErrorID) ? "error" : (isLoadingID || isLoading) ? "info" : "success" } onClose={()=>setMsg("")}></Msg>
            </CardContent>
            {auth.isEscrita() && <CardActions sx={{justifyContent: "center", flexWrap: "wrap"}} disableSpacing>
              <Button variant="contained" onClick={()=>novoPessoa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Novo</Button>
              <Button variant="contained" disabled={isLoadingID} onClick={()=>limparPessoa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Limpar</Button>
              <Button variant="contained" disabled={!editado} onClick={()=>resetPessoa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Reset</Button>
              <Button variant="contained" disabled={(isLoading || !editado || (pessoa?.nome === "" || pessoa?.nome === null || pessoa?.nome === undefined))} onClick={()=>salvaPessoa()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Salvar</Button>
              <Button variant="contained" disabled={isLoading || isLoadingID || (idPessoa === null)} onClick={()=>handleDelete()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Deletar</Button>
              {auth.isAdmin() &&<Button variant="contained" LinkComponent={Link} to="../importar/pessoa" sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Importar</Button>}
            </CardActions>}

          </Card>
          <Grid container spacing={4} sx={{marginTop: "1px"}}>
            <Lista icone={<Person/>} titulo={"Pessoas vinculadas"} linkTitulo="pessoas" itens={pessoa?.pessoas} linkItem={handlePessoa} height={null} btnSecundario={pessoa?._id ? <AddCircle/> : null} btnSecundarioFn={pessoa?._id ? ()=> handleOpenAddDialog("pessoa") : null}/>
            <Lista icone={<InventoryIcon/>} titulo={"Casos vinculados"} linkTitulo="casos" itens={pessoa?.casos} linkItem="casos" height={null} btnSecundario={pessoa?._id ? <AddCircle/> : null} btnSecundarioFn={pessoa?._id ? ()=> handleOpenAddDialog("caso") : null}/>
            <Lista icone={<ApartmentIcon/>} titulo="Empresas vinculadas" linkTitulo="empresas" itens={pessoa?.empresas} linkItem="empresas" height={null} btnSecundario={pessoa?._id ? <AddCircle/> : null} btnSecundarioFn={pessoa?._id ? ()=> handleOpenAddDialog("empresa") : null}/>
            <Lista icone={<WorkIcon/>} titulo="Clientes vinculados" linkTitulo="clientes" itens={pessoa?.clientes} linkItem="clientes" height={null} btnSecundario={pessoa?._id ? <AddCircle/> : null} btnSecundarioFn={pessoa?._id ? ()=> handleOpenAddDialog("cliente") : null}/>
            <Lista icone={<AssignmentIcon/>} titulo="Documentos vinculados" linkTitulo="documentos" itens={pessoa?.documentos} linkItem="documentos" height={null} btnSecundario={pessoa?._id ? <AddCircle/> : null} btnSecundarioFn={pessoa?._id ? ()=> handleOpenAddDialog("documento") : null}/>
          </Grid>
        </Grid>
        {tipoVinculo && <AddVinculoDialog open={addDialogOpen} setOpen={setAddDialogOpen} obj={pessoa} setObj={setPessoa} tipo={tipoVinculo} setEditado={setEditado}/>}
        <ConfirmaDialog open={confirmaOpen} setOpen={setConfirmaOpen} titulo="Alerta" msg={confirmaMsg} handleConfirma={handleConfirmaDelete}/>
      </PaginaBase>
    );
  }
  export default Pessoas;