import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

function Msg({msg, tipo = "info", onClose, block = false}) {

    return (
        <Box sx={{paddingTop: "1rem", minHeight: block ? "4rem" : "1rem"}}>
            {(msg !== "") && <Alert onClose={onClose} severity={tipo} sx={{ width: '100%' }}>
            {msg}
            </Alert>}
        </Box>
    )
}

export default Msg;