import { useState } from "react";

import PaginaBase from "../../global/components/PaginaBase";
import Lista from "../../global/components/Lista";

import TagIcon from '@mui/icons-material/Tag';
import PersonAddIcon from '@mui/icons-material/PersonAdd';



import AddCircle from "@mui/icons-material/AddCircle";
import { UsuarioDialog } from "../components/UsuarioDialog";
import { TagDialog } from "../components/TagDialog";
import { useGetAllUsuarios } from "../services/usuarioService";
import { useGetAllTipos } from "../services/tipoService";

function Admin() {

  const { usuarios, isLoadingUsuarios, isErrorUsuarios } = useGetAllUsuarios();
  const { tipos, isLoadingTipos, isErrorTipos } = useGetAllTipos();
  const [openAddUser, setOpenAddUser] = useState(false);
  const [userId, setUserId] = useState(null);
  const [openAddTipo, setOpenAddTipo] = useState(false);
  const [tipoId, setTipoId] = useState(null);
  
  function handleOpenAddUserDialog (id = null) {
    // console.log("handleOpenAddUserDialog");
    setUserId(id);
    setOpenAddUser(true);
  }

  function handleOpenAddTagDialog (id = null) {
    // console.log("handleOpenAddUserDialog");
    setTipoId(id);
    setOpenAddTipo(true);
  }

    return (
      <PaginaBase xsSpacing={1.2}>
        <Lista icone={<PersonAddIcon/>} titulo="Usuários" linkTitulo="pessoas" itens={usuarios} isError={isErrorUsuarios} isLoading={isLoadingUsuarios} itensCampo="username" linkItem={handleOpenAddUserDialog} btnSecundario={<AddCircle/>} btnSecundarioFn={()=>handleOpenAddUserDialog()}/>
        {tipos && tipos.map((tipo)=> (<Lista key={tipo._id} icone={<TagIcon/>} titulo={"Tags " + tipo.nome} linkTitulo={tipo.nome + "s"} itens={tipo?.tags} isError={isErrorTipos} isLoading={isLoadingTipos} btnSecundario={<AddCircle/>} btnSecundarioFn={()=>handleOpenAddTagDialog(tipo?._id)}/>))}
        <UsuarioDialog open={openAddUser} setOpen={setOpenAddUser} userId={userId}/>
        <TagDialog open={openAddTipo} setOpen={setOpenAddTipo} tipoId={tipoId}/>
      </PaginaBase>
    );
  }
  export default Admin;