import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const TiposApiUrl = process.env.REACT_APP_API_URL + "/tipo/";

export function useGetAllTipos () {
  const { data, error, isLoading } = useSWR(`${TiposApiUrl}`, fetchWrapper.get)
 
  return {
    tipos: data,
    isLoading,
    isError: error
  }
}

export function useGetTagsByNome (nome) {
  const { data, error, isLoading, mutate } = useSWR(nome ? `${TiposApiUrl}nome/${nome}` : null, fetchWrapper.get)
 
  return {
    tags: data?.tags,
    isLoading,
    isError: error,
    mutate
  }
}

export function useGetTipo (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${TiposApiUrl}${id}` : null, fetchWrapper.get)
   
    return {
      tipo: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchTipo (tipo) {
  return fetchWrapper.patch(TiposApiUrl, tipo);
}

export function postTipo (tipo) {
  return fetchWrapper.post(TiposApiUrl, tipo);
}
