import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { useState, useCallback } from "react";
import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  LinearProgress,
  Tooltip,
  IconButton,
  Typography,
  Pagination,
  Stack,
  Divider
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import PaginaBase from "../components/PaginaBase";
import Lista from "../components/Lista";
import ConfirmaDialog from "../components/ConfirmaDialog";
import AlertaDialog from "../components/AlertaDialog";
import { VinculoAutocomplete } from "../components/VinculoAutocomplete";
import { Link } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import {
  useGetLastImportacoes,
  postImportacao,
} from "../services/importacaoService";

const campos = [
  "nome",
  "alcunhas",
  "tags",
  "nc",
  "bl",
  "mae",
  "cpf",
  "rg",
  "dn",
  "obs",
  "resultado"
];

let socket;

function Importar() {
  // const { tipo } = useParams();
  const [file, setFile] = useState(null);
  const [salvar, setSalvar] = useState(false); //true ou false : salva ou só verifica
  const [encontrados, setEncontrados] = useState(false); //true ou false : retorna os registros da base duplicados  
  const [jsonData, setJsonData] = useState(null);
  const [confirmaOpen, setConfirmaOpen] = useState(false);
  const [msgConfirma, setMsgConfirma] = useState("");
  const [salvando, setSalvando] = useState(false);
  const [finalizado, setFinalizado] = useState(false);
  const [vinculo, setVinculo] = useState(null);
  const [alertaOpen, setAlertaOpen] = useState(false);
  const [msgAlerta, setMsgAlerta] = useState("");
  const [progresspercent, setProgresspercent] = useState(null);
  const [status, setStatus] = useState(null);
  // const [isSocket, setIsSocket] = useState(false);

  let navigate = useNavigate();

  const itensPorPagina = 20;
  const [pagina, setPagina] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const {
    importacoes: lastImportacoes,
    isLoading: isLoadingLImportacoes,
    isError: isErrorLImportacoes,
  } = useGetLastImportacoes();

  function corItem(item, campo) {
    let cor = "";
    if (item?.erro && campo === "resultado") {
      cor = "error.main";
    } else if (item?.erroCampos?.some((erro) => erro.campo === campo)) {
      cor = "error.main";
    } else if (!item?.erro && campo === "resultado") {
      cor = "success.main";
    }
    return cor;
  }

  function montaLinhas() {
    if (jsonData.length < 1) return;

    let linhas = [];

    for (
      let i = (pagina - 1) * itensPorPagina;
      i < pagina * itensPorPagina && i < jsonData?.length;
      i++
    ) {
      linhas.push(
        <TableRow key={"r" + i}>
          {campos.map(
            (key) =>
              key !== "erro" &&
              key !== "erroCampos" && (
                <TableCell
                  key={`${key}${i}`}
                  sx={{ color: corItem(jsonData[i], key) }}
                >
                  {
                    <Tooltip
                      title={
                        jsonData[i]?.erroCampos?.find(
                          (erro) => erro?.campo === key
                        )?.erro
                      }
                    >
                      <span style={{ fontWeight: 700 }}>
                        {key === "tags" && Array.isArray(jsonData[i]?.[key])
                          ? jsonData[i]?.[key].join(" | ")
                          : jsonData[i]?.[key]}
                      </span>
                    </Tooltip>
                  }
                </TableCell>
              )
          )}
        </TableRow>
      );
    }

    return linhas;
  }

  function confirmaImportacao(salvar, encontrados) {
    setSalvar(salvar);
    setEncontrados(encontrados);

    setMsgConfirma(
      "Em caso de campos inconsistentes os mesmos serão ignorados." +
        `<br>Deseja ${salvar ? "SALVAR" : "VERIFICAR"} o arquivo assim mesmo?` +
        "<br>Clique em NÃO caso deseje corrigir o arquivo de origem."
    );
    setConfirmaOpen(true);
  }

  async function handleSalvarImportacao(confirma) {
    if (confirma) {
      setSalvando(true);
      setProgresspercent(0);

      socket = io(process.env.REACT_APP_API_SERVER, {
        path: "/api/socket/"
      });

      socket.on("statusImportacao", (status) => {
        const progress = Math.round((status?.processados / status?.total) * 100);
        setProgresspercent(progress);
        setStatus(status);
      });

      socket.on("fimImportacao", (res) => {
        setJsonData(res?.dados);
        setTotalPages(Math.ceil(res?.dados?.length / itensPorPagina));
        setMsgAlerta(
          <>
            <Typography component="span">
              Importação finalizada!
            </Typography>
            <br />
            <Typography color="success.main" component="span">
              Sucessos: {res?.sucessos}
            </Typography>
            <br />
            <Typography color="error.main" component="span">
              Erros: {res?.erros}
            </Typography>
            <br />
            <Typography color="error.main" component="span">
              Duplicados: {res?.duplicados}
            </Typography>
            <br />
            <Typography color="info.main" component="span">
              Total:{res?.dados?.length}
            </Typography>
          </>
        );
        setFinalizado(true);

        setAlertaOpen(true);          
        setSalvando(false);
        setProgresspercent(0);
        // setIsSocket(false);
        socket.disconnect();

      });

      socket.on("erroImportacao", (err) => {
        setMsgAlerta(
          <>
            <Typography component="span">
              Erro na importação!<br />
              {err.message || err.erro}
            </Typography>
          </>
        );          
        setFinalizado(false);

        setAlertaOpen(true);          
        setSalvando(false);
        setProgresspercent(0);
        // setIsSocket(false);
        socket.disconnect();

      });

      socket.on('connect', () => {
        // setIsSocket(true);
        
        // console.log(socket.id);
  
        postImportacao({salvar, encontrados, file, vinculo, socketId: socket.id})
          .then(() => {
          })
          .catch(() => {
          })
          .finally(()=> {
          });
      });
    }
  }

  function handleCancelar() {
    // console.log(socket);
    if (socket && socket.connected) {
      socket.emit('cancelarImportacao', { socketId: socket.id });
      socket.disconnect();
    }
    navigate("../pessoas");
  }

  const exportFile = useCallback(() => {
    const dataPadrao = (data) => {
      // console.log(data);
      let arrData = data.split(/[-/.]+/);
      let ano = arrData[0];
      let mes = arrData[1].padStart(2, "0");
      let dia = arrData[2].padStart(2, "0");
      return `${dia}/${mes}/${ano}`;
    };

    let rows = jsonData.map((item) => ({
      ...item,
      tags: Array.isArray(item?.tags)
      ? item?.tags.join(" | ")
      : item?.tags,
      erroCampos: item?.erroCampos
        ?.map((item) => `${item.campo}(${item.erro})`)
        .join(", "),
      resultado: item?.resultado,
      dn: item?.dn && item?.dn.trim() !== "" ? dataPadrao(item?.dn) : null,
    }));
    /* generate worksheet from state */
    const ws = utils.json_to_sheet(rows);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "importacao");
    /* export to XLSX */
    writeFile(
      wb,
      `resultado_importacao_${new Date().toLocaleDateString()}.xlsx`
    );
  }, [jsonData]);

  function handleSalvarResultado() {
    if (jsonData.length > 0) exportFile();
    // alert("Relatório");
  }

  return (
    <PaginaBase>
      {(!Array.isArray(jsonData)) && (
        <Grid
          item
          xs={12}
          md={3}
          style={{ paddingTop: "1.2rem", paddingLeft: "0px" }}
        >
          <Lista
            icone={<ImportExportIcon />}
            titulo={"Últimas importações"}
            itens={lastImportacoes}
            isError={isErrorLImportacoes}
            isLoading={isLoadingLImportacoes}
            linkItem="importacoes"
            height="calc(90vh - 10rem)"
            sx={{ maxWidth: "2rem" }}
          />
        </Grid>
      )}

    <Grid item xs={12} md={jsonData ? 12 : 9} sx={{height: "100%"}} style={{paddingTop: "1.2rem"}}>
      <Card
        sx={{
          maxHeight: jsonData ? "100%" : "26rem",
          maxWidth: "95%",
          opacity: "0.95",
          marginBottom: "20px",
          paddingBottom: "20px"
        }}
      >
        <CardHeader
          title={<>Importar</>}
          action={
            <IconButton
              LinkComponent={Link}
              to="/arquivos/pessoas.xlsx"
              target="_blank"
              download
            >
              <Tooltip title="Baixar modelo de planilha">
                <FileDownloadIcon />
              </Tooltip>
            </IconButton>
          }
        />
        <CardContent
          sx={{ textAlign: "center", overflow: "auto", maxHeight: jsonData ? "calc(80vh - 12rem)" : "30rem" }}
        >
          {Array.isArray(jsonData) ? (
            <Table>
              <TableHead>
                <TableRow>
                  {campos.map(
                    (key) =>
                      key !== "erro" &&
                      key !== "erroCampos" && (
                        <TableCell key={key} sx={{ fontWeight: 700 }}>
                          {key.toUpperCase()}
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>{montaLinhas()}</TableBody>
            </Table>
          ) : (
            <form>
              <TextField
                type="file"
                name="file"
                label="file"
                fullWidth
                sx={{ marginBottom: "1rem" }}
                inputProps={{ accept: ".xls,.xlsx,.csv" }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFile(e.target.files[0])}
              />
              <VinculoAutocomplete tipo="cliente" setVinculo={setVinculo} />
            </form>
          )}
        </CardContent>
        <CardActions sx={{justifyContent: "center" }}>
          {Array.isArray(jsonData) && (
            <>
              <Pagination
                count={totalPages}
                page={pagina}
                onChange={(e, value) => setPagina(value)}
                sx={{ marginTop: "0.2rem", paddingBottom: "0.2rem" }}
              />
              <br />
            </>
          )}
          {!finalizado && (
            <>
              <Tooltip title="Verificar o arquivo e SALVAR os dados na base">
                <span>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ margin: "0.7rem" }}
                  onClick={() => confirmaImportacao(true, false)}
                  disabled={file === null || salvando}
                >
                  Importar
                </Button>
                </span>
              </Tooltip>
              <Tooltip title="Verificar o arquivo e cruzar com os dados já existentes na base, somente para CHECK ANTES DA IMPORTAÇÃO">
              <span>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                onClick={() => confirmaImportacao(false, false)}
                disabled={file === null || salvando}
              >
                Verificar
              </Button>
              </span>
              </Tooltip>
              <Tooltip title="Verificar o arquivo e cruzar com os dados já existentes na base, retornando os REGISTROS JÁ ENCONTRADOS NA BASE">
              <span>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                onClick={() => confirmaImportacao(false, true)}
                disabled={file === null || salvando}
              >
                Cruzar
              </Button>
              </span>
              </Tooltip>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                onClick={() => handleCancelar(false, true)}
              >
                Cancelar
              </Button>
            </>
          )}
          {finalizado && (
            <>
              <span>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                onClick={() => handleSalvarResultado()}
                disabled={!finalizado}
              >
                Salvar resultado
              </Button>
              </span>
              <Button
                variant="contained"
                size="large"
                sx={{ margin: "0.7rem" }}
                LinkComponent={Link}
                to="../pessoas"
              >
                Encerrar
              </Button>
            </>
          )}
        </CardActions>
          <Grid sx={{ width: "100%", textAlign: "-webkit-center" }}>
          {salvando && progresspercent > 0 && (
            <>
            <LinearProgress
              variant="determinate"
              value={progresspercent}
              sx={{ height: 10, width: "90%", borderRadius: 5 }}
            />
            <Stack direction="row" spacing={2}  sx={{
                justifyContent: "center",
                alignItems: "center",
                padding: "10px"
              }}
              divider={<Divider orientation="vertical" flexItem />}>
              <Typography>SUCESSOS: {status?.sucessos}</Typography>
              <Typography>DUPLICADOS: {status?.duplicados}</Typography>
              <Typography>ERROS: {status?.erros}</Typography>
              <Typography>PROCESSADOS: {status?.processados}</Typography>
              <Typography>TOTAL: {status?.total}</Typography>
            </Stack>
            </>
          )}
          {salvando && progresspercent === 0 && (
            <LinearProgress
              variant="indeterminate"
              sx={{ height: 10, width: "90%", borderRadius: 5 }}
            />
          )}            
          </Grid>
      </Card>
      </Grid>
      <ConfirmaDialog
        open={confirmaOpen}
        setOpen={setConfirmaOpen}
        titulo="Alerta"
        msg={msgConfirma}
        handleConfirma={handleSalvarImportacao}
      />
      <AlertaDialog
        open={alertaOpen}
        setOpen={setAlertaOpen}
        titulo="Alerta"
        msg={msgAlerta}
      />
    </PaginaBase>
  );
}
export default Importar;
