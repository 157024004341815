import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const DocumentoApiUrl = process.env.REACT_APP_API_URL + "/documento";

export function useGetAllDocumentos () {
  const { data, error, isLoading } = useSWR(`${DocumentoApiUrl}s`, fetchWrapper.get)
 
  return {
    documentos: data,
    isLoading,
    isError: error
  }
}

export function useGetBuscaDocumento (search = null, tag = null, page = 1, tipoVinculo = null, vinculo = null, completa = false) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${PessoaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&bl=${bl}`);
  const { data, error, isLoading } = useSWR((search || tag || (tipoVinculo && vinculo) || completa === true) ? `${DocumentoApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&completa=${completa}` : null, fetchWrapper.get);
  // console.log(data);
 
  return {
    documentos: data || {documentos:[]},
    isLoading,
    isError: error
  }
}

export function useGetEstatisticasDocumento (search = null, tag = null, tipoVinculo = null, vinculo = null) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${DocumentoApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`);
  const { data, error, isLoading } = useSWR(`${DocumentoApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}`, fetchWrapper.get);
 
  // console.log(isLoading);
  // console.table(data);

  return {
    documentos: data || {},
    isLoading,
    isError: error
  }
}

export function useGetLastDocumentos (page = 1) {
  const { data, error, isLoading } = useSWR(`${DocumentoApiUrl}s/last?page=${page}`, fetchWrapper.get)
 
  return {
    documentos: data,
    isLoading,
    isError: error
  }
}

export function useGetDocumento (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${DocumentoApiUrl}/${id}` : null, fetchWrapper.get)
    // console.log(data);

    return {
      documento: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchDocumento (documento) {
  return fetchWrapper.patch(DocumentoApiUrl, documento);
}

export function postDocumento (documento) {
  return fetchWrapper.post(DocumentoApiUrl, documento);
}

export function delDocumento (documento) {
  return fetchWrapper.delete(DocumentoApiUrl, documento);
}
