import {useState, useContext, useEffect} from "react";
import { mutate as mutateKey } from "swr"
import { useParams } from "react-router-dom";
import PaginaBase from "../../global/components/PaginaBase";
import { useAuth } from "../../global/components/AuthProvider";
import Msg from "../../global/components/Msg";
import AddVinculoDialog from "../../global/components/AddVinculoDialog";
import { useGetLastCasos, useGetBuscaCaso, useGetCaso, postCaso, patchCaso, delCaso } from "../services/casoService";
import { useGetTagsByNome } from "../../admin/services/tipoService";

import Lista from "../../global/components/Lista";
import { TextField, IconButton, Grid, Card, CardHeader, CardContent, CardActions, Button, Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import InventoryIcon from '@mui/icons-material/Inventory';
import SearchIcon from "@mui/icons-material/Search";
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';

import { AddCircle, Person } from "@mui/icons-material";
import {ColorModeContext} from "../../App";
import ConfirmaDialog from "../../global/components/ConfirmaDialog";

const CasoApiUrl = process.env.REACT_APP_API_URL + "/caso";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Casos() {
  const colorMode = useContext(ColorModeContext);
  const auth = useAuth();

  const { id } = useParams();

  const [inputText, setinputText] = useState("");
  const [tag, setTag] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [searchTag, setSearchTag] = useState(null);

  const [resCaso, setResCaso] = useState(null)
  const [editado, setEditado] = useState(false);
  const [msg, setMsg] = useState("");
  const [idCaso, setIdCaso] = useState(id || null)

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [tipoVinculo, setTipoVinculo] = useState(null);

  const [confirmaMsg, setConfirmaMsg] = useState("");
  const [confirmaOpen, setConfirmaOpen] = useState(false);
  
  const [page, setPage] = useState(1);
  const { casos: lastCasos, isLoading: isLoadingLP, isError: isErrorLP } = useGetLastCasos(page);
  const { casos: buscaCasos, isLoading: isLoadingBP, isError: isErrorBP } = useGetBuscaCaso(searchText, searchTag, page);
  const { caso: casoID, isLoading: isLoadingID, isError: isErrorID, mutate } = useGetCaso(idCaso);
  const { tags, isLoading: isLoadingTags, isError: isErrorTags } = useGetTagsByNome("Caso");

  const [caso, setCaso] = useState(id ? casoID : {tags:[]});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  // const [titCaso, setTitCaso] = useState(id ? casoID?.nome : null);

  // console.log(addDialogOpen);

  // const [tag, setTipo] = useState([]);

  const casoEditTag = (event) => {
    setEditado(true);
    if(resCaso === null) {
      setResCaso(caso);
    }
    // console.log(tag);
    const {
      target: { value },
    } = event;
    // setTipo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setCaso(prevState => ({
      ...prevState,
      "tags": typeof value === 'string' ? value.split(',') : value
    })); 
  };

  function handleCaso(id) {
    setMsg("");
    setIsError(false);
    setEditado(false);
    setResCaso(null);
    setIdCaso(id);
  }

  function casoEdit(e){
    setEditado(true);
    if(resCaso === null) {
      setResCaso(caso);
    }
    
    let { name, value } = e.target;
    // console.log(caso);
    // console.log(name, value)
    if(name === "cpf") {
      value = value.replace(/\D/g, "");
    }

    setCaso(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  function novoCaso(){
    setEditado(false);
    setResCaso(null);
    setIdCaso(null);
    setCaso({});
  }

  function limparCaso(){
    setEditado(idCaso ? true : false);
    setCaso({_id: idCaso});
    // console.log(editado, caso?.nome);
  }

  function resetCaso(){
    if(resCaso !== null){
      setEditado(false);
      setCaso(resCaso);
    }
  }

  function salvaCaso() {
    setIsError(false);
    setIsloading(true);
    setMsg("Salvando...");
    let atualizado = idCaso ? patchCaso : postCaso;
    atualizado(caso)
      .then((data) => {setMsg(data.message);
                        mutateKey(key => typeof key === 'string' && key.startsWith(`${CasoApiUrl}s/last`));
                        mutate();
                        setEditado(false);
                        setResCaso(null);
                        // setCaso({});
                        setIdCaso(data?._id);
                        })
                        .catch((error) => {
                            setIsError(true)
                            setMsg(error.message)
                        })
                        .finally(()=>{
                          setIsloading(false);
                          // setMsg("");
                        });  
    }

  const handleSearchChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setinputText(lowerCase);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    //alert(searchText);
  };

  function handleOpenAddDialog (tipo) {
    // console.log(caso);
    setAddDialogOpen(true);
    setTipoVinculo(tipo);
  }

  function handleDelete() {
    setConfirmaMsg("Deseja deletar a caso?");
    setConfirmaOpen(true);
  }

  function handleConfirmaDelete(confirma) {
    setIsloading(true);
    setIsError(false);
    setMsg("Deletando...");
  if(confirma){
      delCaso(caso)
        .then((data) => {setMsg(data.message);
                          setIsError(false);
                          setIdCaso(null);
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${CasoApiUrl}s/last`));
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${CasoApiUrl}s/busca`));
                          // mutate();
                          setEditado(false);
                          setResCaso(null);
                          // setCaso({});
                          // setTitCaso(null);
                          setIdCaso(null);
                          })
                          .catch((error) => {
                              setIsError(true);
                              setMsg(error.message);
                          })
                          .finally(()=> {
                            setIsloading(false);
                          });       
    }
  }

  useEffect(() => {
    // console.log(editado, caso?.nome);
    setCaso(casoID); 
    setResCaso(casoID); 
    // setTitCaso(casoID?.nome || null);
},[casoID]);

  return (
      <PaginaBase>
        <Grid item xs={12} md={4} style={{paddingTop: "1.2rem", paddingLeft: "0px"}}>
          <Card sx={{marginBottom: "1.5rem", opacity: "0.95"}} elevation={10}>
            <CardHeader avatar={<SearchIcon/>} title="Busca" titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}}/>
            <CardContent>
            <form>
              <TextField
                id="searchbar"
                label="Digite nome ou algum dado da caso"
                variant="outlined"
                placeholder="Busca..."
                size="small"
                sx={{width: 'calc(100% - 50px)', marginLeft: '0.5rem'}}
                onChange={handleSearchChange}
                value={inputText}
                inputProps={{ type: 'search'}}
              />
              <Tooltip title="Buscar">
              <IconButton aria-label="search" onClick={handleClick} type="submit">
                <SearchIcon color={colorMode.mode === "light" ? "primary" : ""}/>
              </IconButton>
              </Tooltip>
              <FormControl size="small" sx={{width: 'calc(100% - 50px)', marginTop: "0.5rem",marginLeft: '0.5rem'}}>
        <InputLabel id="tag-label" shrink>Tag</InputLabel>
        <Select
          labelId="tag-label"
          id="tag"
          value={tag}
          label="Tipo"
          displayEmpty
          onChange={(e) => setTag(e.target.value)}
        ><MenuItem key="vazio" value="">Todos</MenuItem>
          {isErrorTags ? <MenuItem key="Erro" value="Erro">Erro</MenuItem> :
          isLoadingTags ? <MenuItem key="Carregando" value="Carregando">Carregando...</MenuItem> :
           tags && tags.map((tag) => <MenuItem key={tag} value={tag}>{tag}</MenuItem>)}

        </Select>
      </FormControl>      

            </form>
            </CardContent>            
          </Card>
        <Lista icone={<InventoryIcon/>} titulo={(searchText || searchTag) ? "Casos encontrados" : "Últimos casos"} linkTitulo={null} 
          itens={(searchText || searchTag) ? buscaCasos?.casos : lastCasos?.casos} currentPage={page} setPage={setPage}
          totalPages={(searchText || searchTag) ? buscaCasos?.totalPages : lastCasos?.totalPages}
          isError={(searchText || searchTag) ? isErrorBP : isErrorLP} isLoading={(searchText || searchTag) ? isLoadingBP : isLoadingLP} linkItem={handleCaso} height="calc(50vh - 0.5rem)"/>
        </Grid>
        <Grid item xs={12} md={8} style={{paddingTop: "1.2rem"}} sx={{minHeight: "50%"}}>
          <Card sx={{opacity: "0.95"}}>
            <CardHeader avatar={<InventoryIcon/>} titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}} title={idCaso === null ? "Novo Caso" : caso?.nome}></CardHeader>
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item flexGrow={1}>
                    <TextField value={caso?.nome || ""} onChange={(e)=>casoEdit(e)} name="nome" id="nome" label="Nome" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="tags">tags</InputLabel>
                      <Select
                        labelId="tags-label"
                        id="tags"
                        multiple
                        value={caso?.tags || []}
                        onChange={casoEditTag}
                        input={<OutlinedInput id="select-tags" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {tags && tags.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={{fontWeight: caso?.tags?.indexOf(name) === -1 ? "" : "bold"}}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>                
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={caso?.dtEvento ? new Date(caso.dtEvento).toISOString().slice(0, 10): ""} onChange={(e)=>casoEdit(e)} name="dtEvento" id="dtEvento" type="date" label="Data do Evento" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={caso?.obs || ""} onChange={(e)=>casoEdit(e)} name="obs" id="obs" label="Observações" variant="outlined" fullWidth size="small" multiline rows={5} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={caso?.por?.nome || ""} name="por" id="por" label="Alterado por" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={caso?.dtAlteracao ? new Date(caso?.dtAlteracao).toLocaleString() : ""} name="dtAlteracao" id="dtAlteracao" label="Alterado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={caso?.dtCriacao ? new Date(caso?.dtCriacao).toLocaleString() : ""} name="dtCriacao" id="dtCriacao" label="Criado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                </Grid>
              </form>
              <Msg msg={isLoadingID ? "Carregando..." : isErrorID ? "Erro na obtenção dos dados" : msg} tipo={(isError || isErrorID) ? "error" : (isLoadingID || isLoading) ? "info" : "success" } onClose={()=>setMsg("")}></Msg>
            </CardContent>
            {auth.isEscrita() && <CardActions sx={{justifyContent: "center", flexWrap: "wrap"}} disableSpacing>
              <Button variant="contained" onClick={()=>novoCaso()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Novo</Button>
              <Button variant="contained" disabled={isLoadingID} onClick={()=>limparCaso()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Limpar</Button>
              <Button variant="contained" disabled={!editado} onClick={()=>resetCaso()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Reset</Button>
              <Button variant="contained" disabled={(isLoading || !editado || (caso?.nome === "" || caso?.nome === null || caso?.nome === undefined))} onClick={()=>salvaCaso()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Salvar</Button>
              <Button variant="contained" disabled={(isLoading || isLoadingID || idCaso === null)} onClick={()=>handleDelete()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Deletar</Button>
            </CardActions>}

          </Card>
          <Grid container spacing={4} sx={{marginTop: "1px"}}>
            <Lista icone={<Person/>} titulo={"Pessoas vinculadas"} linkTitulo="pessoas" itens={caso?.pessoas} linkItem="pessoas" height={null} btnSecundario={caso?._id ? <AddCircle/> : null} btnSecundarioFn={caso?._id ? ()=> handleOpenAddDialog("pessoa") : null}/>
            <Lista icone={<ApartmentIcon/>} titulo="Empresas vinculadas" linkTitulo="empresas" itens={caso?.empresas} linkItem="empresas" height={null} btnSecundario={caso?._id ? <AddCircle/> : null} btnSecundarioFn={caso?._id ? ()=> handleOpenAddDialog("empresa") : null}/>
            <Lista icone={<WorkIcon/>} titulo="Clientes vinculados" linkTitulo="clientes" itens={caso?.clientes} linkItem="clientes" height={null} btnSecundario={caso?._id ? <AddCircle/> : null} btnSecundarioFn={caso?._id ? ()=> handleOpenAddDialog("cliente") : null}/>
            <Lista icone={<AssignmentIcon/>} titulo="Documentos vinculados" linkTitulo="documentos" itens={caso?.documentos} linkItem="documentos" height={null} btnSecundario={caso?._id ? <AddCircle/> : null} btnSecundarioFn={caso?._id ? ()=> handleOpenAddDialog("documento") : null}/>
          </Grid>
        </Grid>
        {tipoVinculo && <AddVinculoDialog open={addDialogOpen} setOpen={setAddDialogOpen} obj={caso} setObj={setCaso} tipo={tipoVinculo} setEditado={setEditado}/>}
        <ConfirmaDialog open={confirmaOpen} setOpen={setConfirmaOpen} msg={confirmaMsg} handleConfirma={handleConfirmaDelete}/>
      </PaginaBase>
    );
  }
  export default Casos;