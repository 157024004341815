import { useEffect, useState, useContext } from 'react';
import { mutate as mutateKey } from "swr"
import { useGetTipo, patchTipo } from '../services/tipoService';
import Msg from '../../global/components/Msg';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircle from "@mui/icons-material/AddCircle";
import {ColorModeContext} from "../../App";

const TipoApiUrl = process.env.REACT_APP_API_URL + "/tipo/";

export function TagDialog({open, setOpen, tipoId = null}){
    // console.log(tipoId);
    const colorMode = useContext(ColorModeContext);

    const { tipo, isError, isLoading, mutate } = useGetTipo(tipoId);

    const [editado, setEditado] = useState(false);
    const [newTipo, setNewTipo] = useState({tags:[]});  
    const [newTag, setNewTag] = useState("");  
    const [msg, setMsg] = useState("");

    function handleCloseDialog() {
        // console.log(tipo);
        setOpen(false);
        // mutate();
        setEditado(false);
        setNewTipo(tipo ? {...tipo, tags: [...tipo.tags]} : {tags:[]});
        setMsg("");
        setNewTag("");
    }
    
    function handleAddTag() {
        if (newTag !== "" && !newTipo.tags.some((tag)=> tag.toLowerCase() === newTag.toLowerCase())) {
            setNewTipo(prev=> ({...prev, tags: [...prev.tags, newTag]}))
            setEditado(true);
            setNewTag("");
        }
    }

    function handleDelTag(tag) {
        setNewTipo((prev)=> {
            prev.tags.splice(prev.tags.indexOf(tag),1);
            return ({...prev, tags: prev.tags})
            });
        setEditado(true);
    }
    
    async function handleSaveTipo() {
        let atualizado = patchTipo;
        atualizado(newTipo)
          .then((data) => {setMsg(data.message);
                            mutateKey(TipoApiUrl);
                            mutate();
                            setEditado(false)})
          .catch((error) => setMsg(error.message));
    }

    useEffect(() => {
        setNewTipo(tipo ? {...tipo, tags: [...tipo.tags]} : {tags:[]}); 
    },[tipo]);

    return (<Dialog
    open={open}
    onClose={()=>handleCloseDialog()}
    >
        <DialogTitle id="alert-dialog-title">
            {"Tags para " + newTipo?.nome}
        </DialogTitle>		
        <DialogContent sx={{textAlign: "center"}}>
        <form>
            <Grid container spacing={2} sx={{paddingTop: "0.5em"}}>
                <Grid item flexGrow={1} xs={12}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, width: "90%", justifyContent: "center" }}>
                            {newTipo?.tags?.map((value) => (
                              <Chip key={value} label={value} onDelete={()=>handleDelTag(value)}/>
                            ))}
                </Box>
                </Grid>               
                <Grid item flexGrow={1}>
                    <TextField value={newTag} onChange={(e)=>setNewTag(e.target.value)} name="tag" id="tag" label="Tag" variant="outlined" required size="small"
                     InputLabelProps={{shrink: true}} inputProps={{maxLength: 25}}
                     sx={{width:"80%"}}></TextField>
                     <Tooltip title="Adicionar">
                     <IconButton onClick={()=> handleAddTag()}>
                       <AddCircle color={colorMode.mode === "light" ? "primary" : ""}/>
                     </IconButton>
                     </Tooltip>                     
                </Grid> 
            </Grid>
        </form>
        <Msg msg={isLoading ? "Carregando..." : isError ? "Erro na obtenção dos dados" : msg} tipo={isError ? "error" : isLoading ? "info" : "success"} onClose={()=>setMsg("")}></Msg>
        </DialogContent>

        <DialogActions sx={{alignSelf: "center"}}>
            <Button onClick={()=>handleSaveTipo()} variant="contained" disabled={!editado}>Salvar</Button>
            <Button onClick={()=>handleCloseDialog()} variant="contained">{editado ? "Cancelar" : "Fechar"}</Button>
        </DialogActions>
    </Dialog>);


}
