import { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { useTheme} from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {ColorModeContext} from "../../App";
import { Tooltip } from '@mui/material';

function TrocaTema() {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    return (
      <Tooltip title={"Tema " + theme.palette.mode}>
        <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Tooltip>
    );
}
export default TrocaTema;