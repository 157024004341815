import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const AuthApiURL = process.env.REACT_APP_API_URL + "/auth/";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const navigate = useNavigate();

  const logIn = async (username, pwd) => {
    let data = await fetchWrapper.postOpen(AuthApiURL + "signin", {username, pwd}).catch((err)=>{throw(err)});
    // console.log(data);
    if(data?.accessToken) {
        setUser(data);
        sessionStorage.setItem("user", JSON.stringify(data));
        navigate("/home");
        return data;
    }
    else throw new Error("Erro no login!");
}

  const logOut = () => {
    setUser(null);
    sessionStorage.removeItem("user");
    // navigate("/login");
  };

  function isAdmin() {
    return user?.roles?.includes("admin");
  }

  function isEscrita() {
    return user?.roles?.includes("escrita");
  }

  return (
    <AuthContext.Provider value={{ user, logIn, logOut, isAdmin, isEscrita }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};