import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const EmpresaApiUrl = process.env.REACT_APP_API_URL + "/empresa";

export function useGetAllEmpresas () {
  const { data, error, isLoading } = useSWR(`${EmpresaApiUrl}s`, fetchWrapper.get)
 
  return {
    empresas: data,
    isLoading,
    isError: error
  }
}

export function useGetBuscaEmpresa (search = null, tag = null, page = 1, tipoVinculo = null, vinculo = null, nc = null, nnc = null, bl = null, obs = null, nobs = null, indicacao = null, completa = false) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${PessoaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&bl=${bl}`);
  const { data, error, isLoading } = useSWR((search || tag || (tipoVinculo && vinculo) || (nc || nnc || bl || obs || nobs || indicacao) || completa === true) ? `${EmpresaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}&completa=${completa}` : null, fetchWrapper.get);
  // console.log(data);
 
  return {
    empresas: data || {empresas:[]},
    isLoading,
    isError: error
  }
}

export function useGetEstatisticasEmpresa (search = null, tag = null, tipoVinculo = null, vinculo = null, nc = null, nnc = null, bl = null, obs = null, nobs = null, indicacao = null) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${EmpresaApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`);
  const { data, error, isLoading } = useSWR(`${EmpresaApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`, fetchWrapper.get);
 
  // console.log(isLoading);
  // console.table(data);

  return {
    empresas: data || {},
    isLoading,
    isError: error
  }
}

export function useGetLastEmpresas (page = 1) {
  const { data, error, isLoading } = useSWR(`${EmpresaApiUrl}s/last?page=${page}`, fetchWrapper.get)
 
  return {
    empresas: data,
    isLoading,
    isError: error
  }
}

export function useGetEmpresa (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${EmpresaApiUrl}/${id}` : null, fetchWrapper.get)
    // console.log(data);

    return {
      empresa: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchEmpresa (empresa) {
  return fetchWrapper.patch(EmpresaApiUrl, empresa);
}

export function postEmpresa (empresa) {
  return fetchWrapper.post(EmpresaApiUrl, empresa);
}

export function delEmpresa (empresa) {
  return fetchWrapper.delete(EmpresaApiUrl, empresa);
}
