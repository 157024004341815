import { useEffect, useState } from 'react';
import { mutate as mutateKey } from "swr"
import { useGetUsuario, postUsuario, patchUsuario, delUsuario } from '../services/usuarioService';

import Msg from '../../global/components/Msg';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const UsuarioApiUrl = process.env.REACT_APP_API_URL + "/usuario/";


export function UsuarioDialog({open, setOpen, userId = null}){
    // console.log(open);
    // console.log(setOpen);
    const { usuario, isError: isErrorGet, isLoading, mutate } = useGetUsuario(userId);

    const [editado, setEditado] = useState(false);
    const [newUsuario, setNewUsuario] = useState({roles: ['consulta']});  
    const [msg, setMsg] = useState("");
    const [isError, setIsError] = useState(false);
  
    function usuarioEdit(e) {
      let value;
      value = e.target.value;
      if(e.target.name === "username") {
        value = value.replace(/\W/g, '');
      }
      if(e.target.name === "celular") {
        value = value.replace(/\D/g, "");
      }

      setEditado(true);
      setNewUsuario((prevState)=> ({...prevState, [e.target.name]: value}));
    }

    function handleRoles(e) {
        let role = e.target.name;
        let roles = [...newUsuario.roles];
        // console.log(roles);
        if (roles.includes(role))
            roles = roles.filter(x=> x !== role);
        else
            roles.push(role);
        // console.log(roles);
        setNewUsuario({...newUsuario, roles: [...roles]});
        setEditado(true);
    }

    function handleCloseDialog() {
        setOpen(false);
        if (editado) setNewUsuario((userId !== null && usuario) ? usuario : {roles: ['consulta']});
        setEditado(false);
        setMsg("");
        setIsError(false);
    }

    async function handleDelUsuario() {
        setMsg("");
        setIsError(false);
        delUsuario(newUsuario).then((data) => {setMsg(data.message);
                            mutateKey(UsuarioApiUrl);
                            setEditado(false);
                            userId = null;
                            setNewUsuario({roles: ['consulta']});
                        })
                        .catch((error) => {
                            setIsError(true)
                            setMsg(error.message)
                        });
}
    
    
    async function handleSaveUsuario() {
        setMsg("");
        setIsError(false);
        let atualizado = (userId) ? patchUsuario : postUsuario;
        atualizado(newUsuario)
          .then((data) => {setMsg(data.message);
                            mutateKey(UsuarioApiUrl);
                            mutate();
                            setEditado(false);
                            if(!userId) {
                                setNewUsuario({roles: ['consulta']}); 
                            }
                            else {
                                setNewUsuario(usuario);
                            }
                        })
          .catch((error) => {
                                setIsError(true)
                                setMsg(error.message)
                            });
    }

    useEffect(() => {
        setNewUsuario(usuario ? usuario : {roles: ['consulta']}); 
    },[usuario]);

    return (<Dialog
    open={open}
    onClose={()=>handleCloseDialog()}
    >
        <DialogTitle id="alert-dialog-title">
            {newUsuario?.username ? "Editar " + newUsuario?.username : "Adicionar Usuário"}
        </DialogTitle>		
        <DialogContent sx={{textAlign: "center"}}>
        <form>
            <Grid container spacing={2} sx={{paddingTop: "0.5em"}}>
            <Grid item flexGrow={1}>
                <TextField value={newUsuario?.username || ""} onChange={(e)=>usuarioEdit(e)} name="username" id="username" label="Username" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{maxLength: 25}}></TextField>
            </Grid>
            <Grid item flexGrow={1}>
                <TextField value={newUsuario?.pwd || ""} onChange={(e)=>usuarioEdit(e)} name="pwd" id="pwd" label="Senha" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{type: "password"}}></TextField>
            </Grid>
            <Grid item flexGrow={1}>
                <TextField value={newUsuario?.nome || ""} onChange={(e)=>usuarioEdit(e)} name="nome" id="nome" label="Nome" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
            </Grid>
            <Grid item flexGrow={1}>
                <TextField value={newUsuario?.email || ""} onChange={(e)=>usuarioEdit(e)} name="email" id="email" label="Email" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
            </Grid>
            <Grid item flexGrow={1}>
                <TextField value={newUsuario?.celular || ""} onChange={(e)=>usuarioEdit(e)} name="celular" id="celular" label="Celular" placeholder='99999999999' variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}} inputProps={{inputMode: "numeric", maxLength: 11}}></TextField>
            </Grid>
            <Grid item flexGrow={1} sx={{textAlign: "center"}}>
                <FormControlLabel
                control={
                    <Checkbox name="admin" color="warning" checked={newUsuario?.roles?.includes("admin")} onChange={(e)=>handleRoles(e)}/>
                }
                label="Administrador"
                />
                <FormControlLabel
                control={
                    <Checkbox name="escrita" color="warning" checked={newUsuario?.roles?.includes("escrita")} onChange={(e)=>handleRoles(e)}/>
                }
                label="Escrita"
                />
            </Grid>
            </Grid>
        </form>
        <Msg msg={isLoading ? "Carregando..." : isErrorGet ? "Erro na obtenção dos dados" : msg} tipo={(isError || isErrorGet) ? "error" : isLoading ? "info" : "success"} onClose={()=>setMsg("")}></Msg>
        </DialogContent>

        <DialogActions sx={{alignSelf: "center"}}>
            <Button onClick={()=>handleSaveUsuario()} variant="contained" disabled={!editado}>Salvar</Button>
            {newUsuario?._id &&  <Button onClick={()=>handleDelUsuario()} variant="contained">Deletar</Button>}
            <Button onClick={()=>handleCloseDialog()} variant="contained">{editado ? "Cancelar" : "Fechar"}</Button>
        </DialogActions>
    </Dialog>);


}
