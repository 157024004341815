import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const UsuarioApiUrl = process.env.REACT_APP_API_URL + "/usuario/";

export function useGetAllUsuarios () {
  const { data, error, isLoading } = useSWR(`${UsuarioApiUrl}`, fetchWrapper.get)
 
  return {
    usuarios: data,
    isLoading,
    isError: error
  }
}

export function useGetUsuario (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${UsuarioApiUrl}${id}` : null, fetchWrapper.get)
   
    return {
      usuario: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchUsuario (usuario) {
  return fetchWrapper.patch(UsuarioApiUrl, usuario);
}

export function trocaSenhaUsuario (pwd, novopwd) {
  return fetchWrapper.patch(UsuarioApiUrl + "trocasenha", {pwd, novopwd});
}

export function postUsuario (usuario) {
  return fetchWrapper.post(UsuarioApiUrl, usuario);
}

export function delUsuario (usuario) {
  return fetchWrapper.delete(UsuarioApiUrl, usuario);
}
