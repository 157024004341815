import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const ClienteApiUrl = process.env.REACT_APP_API_URL + "/cliente";

export function useGetAllClientes () {
  const { data, error, isLoading } = useSWR(`${ClienteApiUrl}s`, fetchWrapper.get)
 
  return {
    clientes: data,
    isLoading,
    isError: error
  }
}

export function useGetBuscaCliente (search = null, tag = null, page = 1, tipoVinculo = null, vinculo = null, completa = false) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${PessoaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&bl=${bl}`);
  const { data, error, isLoading } = useSWR((search || tag || (tipoVinculo && vinculo) || completa === true) ? `${ClienteApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&completa=${completa}` : null, fetchWrapper.get);
  // console.log(data);
 
  return {
    clientes: data || {clientes:[]},
    isLoading,
    isError: error
  }
}

export function useGetEstatisticasCliente (search = null, tag = null, tipoVinculo = null, vinculo = null) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${ClienteApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`);
  const { data, error, isLoading } = useSWR(`${ClienteApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}`, fetchWrapper.get);
 
  // console.log(isLoading);
  // console.table(data);

  return {
    clientes: data || {},
    isLoading,
    isError: error
  }
}

export function useGetLastClientes (page = 1) {
  const { data, error, isLoading } = useSWR(`${ClienteApiUrl}s/last?page=${page}`, fetchWrapper.get)
 
  return {
    clientes: data,
    isLoading,
    isError: error
  }
}

export function useGetCliente (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${ClienteApiUrl}/${id}` : null, fetchWrapper.get)
    // console.log(data);

    return {
      cliente: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchCliente (cliente) {
  return fetchWrapper.patch(ClienteApiUrl, cliente);
}

export function postCliente (cliente) {
  return fetchWrapper.post(ClienteApiUrl, cliente);
}

export function delCliente (cliente) {
  return fetchWrapper.delete(ClienteApiUrl, cliente);
}
