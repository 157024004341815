import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const ImportacaoApiUrl = process.env.REACT_APP_API_URL + "/importacao";

export function useGetLastImportacoes () {
  const { data, error, isLoading, mutate } = useSWR(`${ImportacaoApiUrl}s/last`, fetchWrapper.get)
 
//   console.log(data);
  return {
    importacoes: data,
    isLoading,
    isError: error,
    mutate
  }
}

export function useGetImportacao (id, page = 1) {
    const { data, error, isLoading } = useSWR(id ? `${ImportacaoApiUrl}/${id}?page=${page}` : null, fetchWrapper.get)

    return {
      importacao: data?.importacao,
      pessoas: data?.pessoas,
      totalPages: data?.totalPages,
      total: data?.total,
      currentPage: data?.currentPage,     
      isLoading,
      isError: error
    }
}

export function postImportacao (importacao) {
  return fetchWrapper.postForm(`${ImportacaoApiUrl}file`, importacao);
}

export function delImportacao (_id) {
  return fetchWrapper.delete(ImportacaoApiUrl, {_id});
}
