import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

export function VinculoAutocomplete({setVinculo, tipo = "cliente", label = "Vincular "}) {
    let tipofn = tipo ? `useGetBusca${tipo.charAt(0).toUpperCase() + tipo.slice(1)}` : "useGetBuscaCliente";
    let strTipo = tipo ? tipo.toLowerCase() : "cliente";

    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const busca = require("../../" + strTipo + "/services/" + strTipo + "Service.js")[tipofn];
    // console.log(busca, "../../" + strTipo + "/services/" + strTipo + "Service.js", tipofn);

    const { [`${strTipo}s`]: itens, isLoading } = busca(inputValue, null, 1);

  function handleChangeAdd(e, newItem)
  {

    if(newItem) {
      let {_id, nome} = newItem;
      setItemSelecionado({_id , nome});
      setVinculo(_id);
      // console.log(itemSelecionado);
    }
    else {
      setItemSelecionado(null);
      setVinculo(null);
    }
  } 

return (<Autocomplete
          id="vinculo"
          name="vinculo"
          value={itemSelecionado || null}
          inputValue={inputValue}
          options={itens[`${strTipo}s`]}
          getOptionLabel={(option) => option?.nome || option._id }
          isOptionEqualToValue={(option, value) => option._id === value._id}    
          renderOption={(props, option) => (
              <li {...props} key={option._id}>
                {option.nome}
              </li>
          )}
          onChange={(e, newValue) => handleChangeAdd(e, newValue)}  
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}                            
          renderInput={(params) => <TextField label={`${label}${tipo}`} {...params} InputProps={{...params.InputProps, ...params.InputProps.ref}} ></TextField>}
          loading={isLoading} loadingText="Carregando..." noOptionsText="Sem opções" sx={{marginTop: "0.4rem", marginBottom: "1rem"}} size="small"/>
        )
}