import {useState, useContext, useEffect} from "react";
import { mutate as mutateKey } from "swr"
import { useParams } from "react-router-dom";
import PaginaBase from "../../global/components/PaginaBase";
import { useAuth } from "../../global/components/AuthProvider";
import Msg from "../../global/components/Msg";
import AddVinculoDialog from "../../global/components/AddVinculoDialog";
import { useGetLastDocumentos, useGetBuscaDocumento, useGetDocumento, postDocumento, patchDocumento, delDocumento } from "../services/documentoService";
import { useGetTagsByNome } from "../../admin/services/tipoService";

import Lista from "../../global/components/Lista";
import { TextField, IconButton, Grid, Card, CardHeader, CardContent, CardActions, Button, Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import SearchIcon from "@mui/icons-material/Search";
import AssignmentIcon from '@mui/icons-material/Assignment';
import InventoryIcon from '@mui/icons-material/Inventory';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WorkIcon from '@mui/icons-material/Work';

import { AddCircle, Person } from "@mui/icons-material";
import {ColorModeContext} from "../../App";
import ConfirmaDialog from "../../global/components/ConfirmaDialog";

const DocumentoApiUrl = process.env.REACT_APP_API_URL + "/documento";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Documentos() {
  const colorMode = useContext(ColorModeContext);
  const auth = useAuth();

  const { id } = useParams();

  const [inputText, setinputText] = useState("");
  const [tag, setTag] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [searchTag, setSearchTag] = useState(null);

  const [resDocumento, setResDocumento] = useState(null)
  const [editado, setEditado] = useState(false);
  const [msg, setMsg] = useState("");
  const [idDocumento, setIdDocumento] = useState(id || null)

  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [tipoVinculo, setTipoVinculo] = useState(null);

  const [confirmaMsg, setConfirmaMsg] = useState("");
  const [confirmaOpen, setConfirmaOpen] = useState(false);
  
  const [page, setPage] = useState(1);
  const { documentos: lastDocumentos, isLoading: isLoadingLP, isError: isErrorLP } = useGetLastDocumentos(page);
  const { documentos: buscaDocumentos, isLoading: isLoadingBP, isError: isErrorBP } = useGetBuscaDocumento(searchText, searchTag, page);
  const { documento: documentoID, isLoading: isLoadingID, isError: isErrorID, mutate } = useGetDocumento(idDocumento);
  const { tags, isLoading: isLoadingTags, isError: isErrorTags } = useGetTagsByNome("Documento");

  const [documento, setDocumento] = useState(id ? documentoID : {tags:[]});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  // const [titDocumento, setTitDocumento] = useState(id ? documentoID?.nome : null);

  // console.log(addDialogOpen);

  // const [tag, setTipo] = useState([]);

  const documentoEditTag = (event) => {
    setEditado(true);
    if(resDocumento === null) {
      setResDocumento(documento);
    }
    // console.log(tag);
    const {
      target: { value },
    } = event;
    // setTipo(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setDocumento(prevState => ({
      ...prevState,
      "tags": typeof value === 'string' ? value.split(',') : value
    })); 
  };

  function handleDocumento(id) {
    setMsg("");
    setIsError(false);
    setEditado(false);
    setResDocumento(null);
    setIdDocumento(id);
  }

  function documentoEdit(e){
    setEditado(true);
    if(resDocumento === null) {
      setResDocumento(documento);
    }
    
    let { name, value } = e.target;
    // console.log(documento);
    // console.log(name, value)
    if(name === "cnpj" || name === "telefone") {
      value = value.replace(/\D/g, "");
    }

    setDocumento(prevState => ({
        ...prevState,
        [name]: value
    }));
  }

  function novoDocumento(){
    setEditado(false);
    setResDocumento(null);
    setIdDocumento(null);
    setDocumento({});
  }

  function limparDocumento(){
    setEditado(idDocumento ? true : false);
    setDocumento({_id: idDocumento});
    // console.log(editado, documento?.nome);
  }

  function resetDocumento(){
    if(resDocumento !== null){
      setEditado(false);
      setDocumento(resDocumento);
    }
  }

  function salvaDocumento() {
    setIsloading(true);
    setMsg("Salvando...");
    let atualizado = idDocumento ? patchDocumento : postDocumento;
    atualizado(documento)
      .then((data) => {setMsg(data.message);
                        mutateKey(key => typeof key === 'string' && key.startsWith(`${DocumentoApiUrl}s/last`));
                        mutate();
                        setEditado(false);
                        setResDocumento(null);
                        // setDocumento({});
                        setIdDocumento(data?._id);
                        })
                        .catch((error) => {
                            setIsError(true)
                            setMsg(error.message)
                        })
                        .finally(()=>{
                          setIsloading(false);
                          // setMsg("");
                        });  
    }

  const handleSearchChange = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setinputText(lowerCase);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setPage(1);
    setSearchText(inputText);
    setSearchTag(tag || null);
    //alert(searchText);
  };

  function handleOpenAddDialog (tipo) {
    // console.log(documento);
    setAddDialogOpen(true);
    setTipoVinculo(tipo);
  }

  function handleDelete() {
    setConfirmaMsg("Deseja deletar a documento?");
    setConfirmaOpen(true);
  }

  function handleConfirmaDelete(confirma) {
    setIsloading(true);
    setIsError(false);
    setMsg("Deletando...");
    if(confirma){
      delDocumento(documento)
        .then((data) => {setMsg(data.message);
                          setIdDocumento(null);
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${DocumentoApiUrl}s/last`));
                          mutateKey(key => typeof key === 'string' && key.startsWith(`${DocumentoApiUrl}s/busca`));
                          // mutate();
                          setEditado(false);
                          setResDocumento(null);
                          // setDocumento({});
                          // setTitDocumento(null);
                          setIdDocumento(null);
                          })
                          .catch((error) => {
                              setIsError(true)
                              setMsg(error.message)
                          })
                          .finally(()=> {
                            setIsloading(false);
                          });        
      }
  }

  useEffect(() => {
    // console.log(editado, documento?.nome);
    setDocumento(documentoID); 
    setResDocumento(documentoID); 
    // setTitDocumento(documentoID?.nome || null);
},[documentoID]);

  return (
      <PaginaBase>
        <Grid item xs={12} md={4} style={{paddingTop: "1.2rem", paddingLeft: "0px"}}>
          <Card sx={{marginBottom: "1.5rem", opacity: "0.95"}} elevation={10}>
            <CardHeader avatar={<SearchIcon/>} title="Busca" titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}}/>
            <CardContent>
            <form>
              <TextField
                id="searchbar"
                label="Digite nome ou algum dado da documento"
                variant="outlined"
                placeholder="Busca..."
                size="small"
                sx={{width: 'calc(100% - 50px)', marginLeft: '0.5rem'}}
                onChange={handleSearchChange}
                value={inputText}
                inputProps={{ type: 'search'}}
              />
              <Tooltip title="Buscar">
              <IconButton aria-label="search" onClick={handleClick} type="submit">
                <SearchIcon color={colorMode.mode === "light" ? "primary" : ""}/>
              </IconButton>
              </Tooltip>
              <FormControl size="small" sx={{width: 'calc(100% - 50px)', marginTop: "0.5rem",marginLeft: '0.5rem'}}>
        <InputLabel id="tag-label" shrink>Tag</InputLabel>
        <Select
          labelId="tag-label"
          id="tag"
          value={tag}
          label="Tipo"
          displayEmpty
          onChange={(e) => setTag(e.target.value)}
        ><MenuItem key="vazio" value="">Todos</MenuItem>
          {isErrorTags ? <MenuItem key="Erro" value="Erro">Erro</MenuItem> :
          isLoadingTags ? <MenuItem key="Carregando" value="Carregando">Carregando...</MenuItem> :
           tags && tags.map((tag) => <MenuItem key={tag} value={tag}>{tag}</MenuItem>)}

        </Select>
      </FormControl>      

            </form>
            </CardContent>            
          </Card>
        <Lista icone={<AssignmentIcon/>} titulo={(searchText || searchTag) ? "Documentos encontrados" : "Últimos documentos"} linkTitulo={null} 
          itens={(searchText || searchTag) ? buscaDocumentos?.documentos : lastDocumentos?.documentos} currentPage={page} setPage={setPage}
          totalPages={(searchText || searchTag) ? buscaDocumentos?.totalPages : lastDocumentos?.totalPages}
          isError={(searchText || searchTag) ? isErrorBP : isErrorLP} isLoading={(searchText || searchTag) ? isLoadingBP : isLoadingLP} linkItem={handleDocumento} height="calc(50vh - 0.5rem)"/>
        </Grid>
        <Grid item xs={12} md={8} style={{paddingTop: "1.2rem"}} sx={{minHeight: "50%"}}>
          <Card sx={{opacity: "0.95"}}>
            <CardHeader avatar={<AssignmentIcon/>} titleTypographyProps={{fontSize: "1em", fontWeight: 700, letterSpacing: "0"}} title={idDocumento === null ? "Novo Documento" : documento?.nome}></CardHeader>
            <CardContent>
              <form>
                <Grid container spacing={2}>
                  <Grid item flexGrow={1}>
                    <TextField value={documento?.nome || ""} onChange={(e)=>documentoEdit(e)} name="nome" id="nome" label="Nome" variant="outlined" required fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="tags">tags</InputLabel>
                      <Select
                        labelId="tags-label"
                        id="tags"
                        multiple
                        value={documento?.tags || []}
                        onChange={documentoEditTag}
                        input={<OutlinedInput id="select-tags" label="Chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {tags && tags.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={{fontWeight: documento?.tags?.indexOf(name) === -1 ? "" : "bold"}}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>                
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={documento?.url || ""}  onChange={(e)=>documentoEdit(e)} name="url" id="url" label="URL" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={documento?.texto || ""} onChange={(e)=>documentoEdit(e)} name="texto" id="texto" label="Texto" variant="outlined" fullWidth size="small" multiline rows={5} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item sx={{width: "100%"}}>
                    <TextField value={documento?.obs || ""} onChange={(e)=>documentoEdit(e)} name="obs" id="obs" label="Observações" variant="outlined" fullWidth size="small" multiline rows={5} InputLabelProps={{shrink: true}}></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={documento?.por?.nome || ""} name="por" id="por" label="Alterado por" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={documento?.dtAlteracao ? new Date(documento?.dtAlteracao).toLocaleString() : ""} name="dtAlteracao" id="dtAlteracao" label="Alterado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                  <Grid item flexGrow={1}>
                    <TextField value={documento?.dtCriacao ? new Date(documento?.dtCriacao).toLocaleString() : ""} name="dtCriacao" id="dtCriacao" label="Criado em" variant="outlined" fullWidth size="small" InputLabelProps={{shrink: true}} disabled></TextField>
                  </Grid>
                </Grid>
              </form>
              <Msg msg={isLoadingID ? "Carregando..." : isErrorID ? "Erro na obtenção dos dados" : msg} tipo={(isError || isErrorID) ? "error" : (isLoadingID || isLoading) ? "info" : "success" } onClose={()=>setMsg("")}></Msg>
            </CardContent>
            {auth.isEscrita() && <CardActions sx={{justifyContent: "center", flexWrap: "wrap"}} disableSpacing>
              <Button variant="contained" onClick={()=>novoDocumento()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Novo</Button>
              <Button variant="contained" disabled={isLoadingID} onClick={()=>limparDocumento()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Limpar</Button>
              <Button variant="contained" disabled={!editado} onClick={()=>resetDocumento()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Reset</Button>
              <Button variant="contained" disabled={(isLoading || !editado || (documento?.nome === "" || documento?.nome === null || documento?.nome === undefined))} onClick={()=>salvaDocumento()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Salvar</Button>
              <Button variant="contained" disabled={isLoading || isLoadingID || (idDocumento === null)} onClick={()=>handleDelete()} sx={{minWidth: "5.5rem", margin: "0.3rem"}}>Deletar</Button>
            </CardActions>}

          </Card>
          <Grid container spacing={4} sx={{marginTop: "1px"}}>
            <Lista icone={<Person/>} titulo={"Pessoas vinculadas"} linkTitulo="pessoas" itens={documento?.pessoas} linkItem="pessoas" height={null} btnSecundario={documento?._id ? <AddCircle/> : null} btnSecundarioFn={documento?._id ? ()=> handleOpenAddDialog("pessoa") : null}/>
            <Lista icone={<InventoryIcon/>} titulo={"Casos vinculados"} linkTitulo="casos" itens={documento?.casos} linkItem="casos" height={null} btnSecundario={documento?._id ? <AddCircle/> : null} btnSecundarioFn={documento?._id ? ()=> handleOpenAddDialog("caso") : null}/>
            <Lista icone={<ApartmentIcon/>} titulo="Empresas vinculadas" linkTitulo="empresas" itens={documento?.empresas} linkItem="empresas" height={null} btnSecundario={documento?._id ? <AddCircle/> : null} btnSecundarioFn={documento?._id ? ()=> handleOpenAddDialog("empresa") : null}/>
            <Lista icone={<WorkIcon/>} titulo="Clientes vinculados" linkTitulo="clientes" itens={documento?.clientes} linkItem="clientes" height={null} btnSecundario={documento?._id ? <AddCircle/> : null} btnSecundarioFn={documento?._id ? ()=> handleOpenAddDialog("cliente") : null}/>
          </Grid>
        </Grid>
        {tipoVinculo && <AddVinculoDialog open={addDialogOpen} setOpen={setAddDialogOpen} obj={documento} setObj={setDocumento} tipo={tipoVinculo} setEditado={setEditado}/>}
        <ConfirmaDialog open={confirmaOpen} setOpen={setConfirmaOpen} msg={confirmaMsg} handleConfirma={handleConfirmaDelete}/>
      </PaginaBase>
    );
  }
  export default Documentos;