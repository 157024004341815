import { useDrawingArea } from "@mui/x-charts/hooks";

import { useRef } from "react";

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  CircularProgress
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
// import {
//   blueberryTwilightPalette,
//   mangoFusionPalette,
//   cheerfulFiestaPalette,
// } from "@mui/x-charts/colorPalettes";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Person from "@mui/icons-material/Person";
import Apartment from "@mui/icons-material/Apartment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InventoryIcon from "@mui/icons-material/Inventory";
import WorkIcon from "@mui/icons-material/Work";

const downloadSVGAsImage = (svgElement, imageType = "png") => {
  const svgData = new XMLSerializer().serializeToString(svgElement);
  const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
  const url = URL.createObjectURL(svgBlob);
  const image = new Image();
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  image.onload = () => {
    canvas.width = image.width;
    canvas.height = image.height;
    context.drawImage(image, 0, 0);
    URL.revokeObjectURL(url);
    const imgURL = canvas.toDataURL(`image/${imageType}`);
    const downloadLink = document.createElement("a");
    downloadLink.href = imgURL;
    downloadLink.download = `chart.${imageType}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  image.src = url;
};

const icons = {
  pessoa: <Person />,
  empresa: <Apartment />,
  documento: <AssignmentIcon />,
  cliente: <WorkIcon />,
  caso: <InventoryIcon />,
};


function PieLabel({ children }) {
  const theme = useTheme();
  const { width, left, top } = useDrawingArea();

  return (
    <text
      x={left + width / 2}
      y={top + 1}
      style={{
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: "0.00938em",
        color: "inherit",
        textAnchor: "middle",
        dominantBaseline: "central",
        fill: theme.palette.text.primary,
      }}
    >
      {children}
    </text>
  );
}

export default function GraficoEstatisticas({ tipo, params = {} }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const coresByTratamento = [theme.palette.success.main, theme.palette.warning.dark];
  const coresByFlag = [theme.palette.info.dark, theme.palette.error.main, theme.palette.warning.light, theme.palette.secondary.main];

  const strTipo = tipo ? tipo.toLocaleLowerCase() : "pessoa";

  const tipofn = `useGetEstatisticas${
    strTipo.charAt(0).toUpperCase() + strTipo.slice(1)
  }`;

  const busca = require("../../" +
    strTipo +
    "/services/" +
    strTipo +
    "Service.js")[tipofn];
  // console.log(inputText, "../../" + strTipo + "/services/" + strTipo + "Service.js", tipofn);

  let { searchText, tag, tipoVinculo, vinculo, nc, nnc, bl, obs, nobs, indicacao } =
    params;

  // console.log(params);

  const {
    [`${strTipo}s`]: totais,
    isLoading,
    isError,
  } = busca(
    searchText,
    tag,
    tipoVinculo,
    vinculo,
    nc,
    nnc,
    bl,
    obs,
    nobs,
    indicacao
  );

  const svgRef = useRef(null);

  const handleDownload = () => {
    if (svgRef.current) {
      // Encontrar o elemento SVG dentro do componente PieChart
      const svgElement = svgRef.current.querySelector("svg");
      if (svgElement) {
        downloadSVGAsImage(svgElement, "png"); // ou 'jpeg'
      }
    }
  };

  const series = [
    {
      innerRadius: 20,
      outerRadius: 55,
      // arcLabel: (item) => `${item.label} (${item.value})`,
      id: "series-1",
      data: Array.isArray(totais?.totalByTag)
        ? totais?.totalByTag.map((item) => ({
            ...item,
            label: (location) =>
              location === "legend"
                ? `${item.label} (${item.value})`
                : item.label,
          }))
        : [],
      // valueFormatter: () => {
      //   return ``;
      // },

      // colors: blueberryTwilightPalette, //TODO: CORES, COMO MUDAR POR SÉRIE
    },
    {
      innerRadius: 65,
      outerRadius: 105,
      // arcLabel: (item) => `${item.label} (${item.value})`,
      id: "series-2",
      data: Array.isArray(totais?.totalByFlag)
        ? totais?.totalByFlag.map((item, index) => ({
            ...item,
            label: (location) =>
              location === "legend"
                ? `${item.label} (${item.value})`
                : item.label,
                color: coresByFlag[index]
          }))
        : [],
      // valueFormatter: () => {
      //   return ``;
      // },
      // color: mangoFusionPalette,
    },
    {
      innerRadius: 115,
      outerRadius: 130,
      // arcLabel: (item) => `${item.label} (${item.value})`,
      id: "series-3",
      data: Array.isArray(totais?.totalByTratamento)
        ? totais?.totalByTratamento.map((item, index) => ({
            ...item,
            label: (location) =>
              location === "legend"
                ? `${item.label} (${item.value})`
                : item.label,
            color: coresByTratamento[index]
          }))
        : [],
      // valueFormatter: () => {
      //   return ``;
      // },
      // color: cheerfulFiestaPalette,
    },
  ];

  let totalLegendas =
    series[0]?.data?.length + series[1]?.data?.length + series[2]?.data?.length;
  // console.log(totalLegendas);

  return (
    <Grid item flexGrow={1}>
      <Card sx={{ opacity: "0.95" }}>
        <CardHeader
          avatar={icons[strTipo]}
          titleTypographyProps={{
            fontSize: "0.9em",
            fontWeight: 700,
            letterSpacing: "0",
          }}
          title={`Estatísticas de ${strTipo}s`}
          action={
            <IconButton onClick={handleDownload}>
              <Tooltip title="Baixar gráfico">
                <FileDownloadIcon />
              </Tooltip>
            </IconButton>
          }
          sx={{ paddingTop: "0.6rem", paddingBottom: "0.6rem" }}
        ></CardHeader>
        <Divider />
        <CardContent
          sx={{
            paddingTop: "5px",
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13))",
          }}
        >
          {isLoading && <div
              style={{
                textAlign: "center",
                height: "50%",
                width: "100%",
                alignContent: "center",
              }}
            >
              <CircularProgress />
            </div>}
          {!isLoading && !isError && (
            <div ref={svgRef}>
              <PieChart
                series={series}
                width={isMobile ? 300 : totalLegendas > 10 ? 520 : 380}
                height={300}
                slotProps={{
                  legend: {
                    hidden: isMobile,
                    labelStyle: {
                      fontSize: 10,
                    },
                    padding: {
                      left: 0,
                      right: -10,
                      top: 0,
                      bottom: 0,
                    },
                  },
                }}
                margin={{
                  left: isMobile ? 0 : totalLegendas > 10 ? -260 : -110,
                  right: 0,
                  top: 10,
                  bottom: 0,
                }}
              >
                <PieLabel>
                  {totais.total} {strTipo}
                  {totais.total > 1 ? "s" : ""}
                </PieLabel>
              </PieChart>
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
