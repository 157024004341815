import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Paper,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Pagination,
  TextField,
  Checkbox,
} from "@mui/material";
import { useState, useContext, useEffect } from "react";

import { Link } from "react-router-dom";
import { ColorModeContext } from "../../App";

export default function Lista({
  icone,
  titulo,
  linkTitulo,
  itens = [],
  currentPage = null,
  setPage = null,
  totalPages = null,
  isError = null,
  isLoading = null,
  itensCampo = null,
  linkItem,
  height = "calc(50vh - 7.3rem)",
  btnSecundario = null,
  btnSecundarioFn = null,
  btnItem = null,
  btnItemFn = null,
  btnItemInfo = null,
  checkBox = false,
}) {
  // function handleTitClick(link) {
  //   window.open(link);
  // }
  const itensPorPagina = 20;

  const colorMode = useContext(ColorModeContext);

  const [pagina, setPagina] = useState(1);
  const [search, setSearch] = useState("");
  const [checked, setChecked] = useState([]);
  const [selected, setSelected] = useState();

  if (totalPages && totalPages === 1) {
    if (height === null) height = "calc(50vh - 5rem)";
  }
  if (totalPages && totalPages > 1) {
    if (height === null) height = "calc(50vh - 7.3rem)";
  } else if (totalPages === null && itens.length > itensPorPagina) {
    if (height === null) height = "calc(50vh - 7rem)";
    totalPages = Math.ceil(itens.length / itensPorPagina);
  } else if (itens.length === 0) {
    height = null;
  }

  function handleClickItem(fn, data) {
    // console.log(data);
    fn(data);
    setSelected(data);
  }

  function handleClickBtnSecundario(e, fn, data) {
    e.stopPropagation();
    // alert(data);
    fn(data);
  }

  function handleSearch(texto) {
    setPagina(1);
    setSearch(texto);
  }

  function handleCheck(e, _id) {
    e.preventDefault();

    let index = itens.findIndex((item) => item._id === _id);

    itens[index].checked = itens[index]?.checked ? false : true;

    const currentIndex = checked.indexOf(_id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(_id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  function montaLinhas() {
    let itensFiltrados = [];
    let linhas = [];

    if (search !== "") {
      itensFiltrados = itens.filter((item) => {
        if (item[itensCampo])
          return item[itensCampo].toLowerCase().includes(search.toLowerCase());
        else if (typeof item === "string")
          return item.toLowerCase().includes(search.toLowerCase());
        else return item?.nome.toLowerCase().includes(search.toLowerCase());
      });
      totalPages = Math.ceil(itensFiltrados.length / itensPorPagina);
    } else {
      itensFiltrados = itens;
    }

    for (
      let i = (pagina - 1) * itensPorPagina;
      i < pagina * itensPorPagina;
      i++
    ) {
      // for(let i=0; i<itens.length; i++) {
      // console.log(i);
      if (i >= itensFiltrados.length) break;
      linhas.push(
        <ListItem
          key={
            itensFiltrados[i]?._id ? itensFiltrados[i]._id : itensFiltrados[i]
          }
          disablePadding
          secondaryAction={
            btnItem && (
              <Tooltip title={btnItemInfo}>
                <IconButton onClick={() => btnItemFn(itensFiltrados[i])}>
                  {btnItem}
                </IconButton>
              </Tooltip>
            )
          }
        >
          <ListItemButton
            onClick={
              linkItem && typeof linkItem !== "string"
                ? () => handleClickItem(linkItem, itensFiltrados[i]._id)
                : null
            }
            to={
              typeof linkItem === "string"
                ? "../" + linkItem + "/" + itensFiltrados[i]._id
                : null
            }
            LinkComponent={Link}
            selected={
              typeof itensFiltrados[i] !== "string" &&
              itensFiltrados[i]?._id === selected
            }
          >
            {checkBox && typeof itensFiltrados[i] !== "string" && (
              <Tooltip
                title={`Adicionar ${itensFiltrados[i]?.nome} ao relatório`}
              >
                <Checkbox
                  edge="start"
                  onClick={(e) => handleCheck(e, itensFiltrados[i]?._id)}
                  checked={checked.indexOf(itensFiltrados[i]?._id) !== -1}
                  // tabIndex={-1}
                  // disableRipple
                  // inputProps={{ 'aria-labelledby': labelId }}
                />
              </Tooltip>
            )}
            <ListItemText
              primary={
                itensCampo
                  ? itensFiltrados[i]?.[itensCampo]
                  : typeof itensFiltrados[i] === "string"
                  ? itensFiltrados[i]
                  : itensFiltrados[i]?.nome
              }
            />
          </ListItemButton>
        </ListItem>
      );
    }

    // itens.map((item)=>(
    //   <ListItem key={item?._id ? item._id : item} disablePadding secondaryAction={btnItem && <Tooltip title={btnItemInfo}>
    //     <IconButton onClick={()=>btnItemFn(item)}>
    //       {btnItem}
    //     </IconButton>
    //   </Tooltip>}>
    //   <ListItemButton onClick={((linkItem && typeof linkItem !== "string") ? (()=>handleClickItem(linkItem, item._id)) : null)} to={typeof linkItem === "string" ? "../" + linkItem + "/" + item._id : null} LinkComponent={Link}>
    //     <ListItemText primary={itensCampo ? item?.[itensCampo] : typeof item === "string" ? item : item?.nome} />
    //   </ListItemButton>
    //   </ListItem>
    // ))

    return linhas;
  }

  useEffect(() => {
    if (checkBox) setChecked([]);
  }, [itens, checkBox]);

  return (
    <Grid item flexGrow={1}>
      <Paper elevation={10} sx={{ opacity: "0.95" }}>
        <List
          sx={{
            overflow: "auto",
            height,
            minHeight: "100%",
            paddingTop: "0px",
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
          }}
        >
          <ListSubheader sx={{ display: "flex", paddingTop: "0.3rem" }}>
            <ListItemButton
              to={linkTitulo && "../" + linkTitulo}
              LinkComponent={linkTitulo && Link}
            >
              <ListItemIcon>{icone}</ListItemIcon>
              <ListItemText
                primary={titulo}
                primaryTypographyProps={{
                  fontSize: "1.1em",
                  fontWeight: "bold",
                  letterSpacing: 0,
                  color: "#fff",
                }}
              />
            </ListItemButton>

            {btnSecundario ? (
              <Tooltip title="ver mais...">
                <IconButton
                  sx={{
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      height: "70%",
                      display: "block",
                      left: 0,
                      width: "1px",
                      bgcolor: "divider",
                    },
                    // justifyContent: 'center',
                    // paddingTop: "0px",
                    // paddingBottom: "0px"
                  }}
                  onClick={(e) =>
                    handleClickBtnSecundario(e, btnSecundarioFn, true)
                  }
                >
                  {btnSecundario}
                </IconButton>
              </Tooltip>
            ) : (
              <div style={{ minWidth: "2rem" }}></div>
            )}
            {totalPages > 1 && setPage === null && (
              <ListItemText>
                <TextField
                  value={search}
                  label="Busca"
                  placeholder="Nome"
                  onChange={(e) => handleSearch(e.target.value)}
                  fullWidth
                  size="small"
                  color={colorMode.mode === "light" ? "info" : ""}
                  InputLabelProps={{
                    sx: { color: colorMode.mode === "light" ? "#FFFFFF" : "" },
                  }}
                  inputProps={{
                    sx: { color: colorMode.mode === "light" ? "#FFFFFF" : "" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: colorMode.mode === "light" ? "#FFFFFF" : "",
                      color: colorMode.mode === "light" ? "#FFFFFF" : "",
                    },

                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colorMode.mode === "light" ? "#FFFFFF" : "",
                      },
                    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor:
                          colorMode.mode === "light" ? "#FFFFFF" : "",
                        color: colorMode.mode === "light" ? "#FFFFFF" : "",
                      },
                    "& .MuiFormLabel-root.MuiInputLabel-root": {
                      borderColor: colorMode.mode === "light" ? "#FFFFFF" : "",
                      color: colorMode.mode === "light" ? "#FFFFFF" : "",
                    },
                  }}
                ></TextField>
              </ListItemText>
            )}
          </ListSubheader>

          <Divider />
          {isLoading ? (
            <div
              style={{
                textAlign: "center",
                height: "50%",
                alignContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            (isError || itens.length < 1) && (
              <Typography
                align="center"
                sx={{ height: "50%", alignContent: "center" }}
              >
                Sem Dados
              </Typography>
            )
          )}
          {!isError && itens.length > 0 && montaLinhas()}
        </List>
        {totalPages > 1 && setPage !== null && (
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(e, value) => setPage(value)}
            sx={{ marginTop: "0.2rem", paddingBottom: "0.2rem" }}
          />
        )}
        {totalPages > 1 && setPage === null && (
          <Pagination
            count={totalPages}
            page={pagina}
            onChange={(e, value) => setPagina(value)}
            sx={{ marginTop: "0.2rem", paddingBottom: "0.2rem" }}
          />
        )}
      </Paper>
    </Grid>
  );
}
