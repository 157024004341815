import { Buffer } from 'buffer'
const AuthApiURL = process.env.REACT_APP_API_URL + "/auth/";

export const fetchWrapper = {
    get,
    getOpen,
    post,
    postForm,
    postOpen,
    patch,
    delete: _delete
};

async function get(url) {

    // console.log(await idToken());

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + await idToken(),
        }
    };

    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

function getOpen(url) {

    const requestOptions = {
        method: 'GET'
    };

    return fetch(url, requestOptions).then(handleResponse);
}

async function postOpen(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

async function post(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + await idToken(),
        'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

async function postForm(url, data) {
    const formData = new FormData();
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
        }
    }

    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization': 'Bearer ' + await idToken(),
            // Note que 'Content-Type' não é necessário definir para 'multipart/form-data'
        },
        body: formData
    };

    return fetch(url, requestOptions)
        .then(handleResponse)
        .catch(handleError);
}

async function patch(url, body) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Authorization': 'Bearer ' + await idToken(),
        'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions)
        .then(handleResponse)  
        .catch(handleError);
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url, body) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Authorization': 'Bearer ' + await idToken(),
        'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

// helper functions

function handleResponse(response) {
    return response.text().then(text => {
        try {
            let data;
            if(response.status === 401) { //caso sessão caia, token não liberado, joga para o login e limpa sessão
                sessionStorage.clear();
                window.location.href = "/login";
            }
            if (response.ok) {
                data = text && JSON.parse(text);
            }
            else {
                const error = {status: response.status,
                    statusText: response.statusText, 
                    message: JSON.parse(text.replace(/(\r\n|\n|\r)/gm, "")).message}
                
                // console.log(error);
                throw(error);
            }

            return data;
        }
        catch (error) {
            if(response.status === 401) {//caso sessão caia, token não liberado, joga para o login e limpa sessão
                sessionStorage.clear();
                window.location.href = "/login";
            }
            // console.log(error);
            const err = {status: response.status,
                statusText: response.statusText, 
                message: error.message}          
            // console.log(err);
            throw(err);
        }
    });
}

function handleError(error) {
    // console.table(error);
    // const err = {status: response.status,
    //     statusText: response.statusText, 
    //     message: error.message}          
    throw(new Error(error.message === "Failed to fetch" ? "Erro de conexão" : error.message));    
}

async function idToken() {
    let accessToken = (JSON.parse(sessionStorage.getItem("user")).accessToken);
    // console.log(accessToken);
    if (accessToken) {
        let token = accessToken.split(".")[1];
        token = Buffer.from(token, 'base64').toString();
        token = JSON.parse(token);
        let d = new Date();
        // d.setHours(d.getHours() + 2); // expirado
        // console.log(new Date(token.exp * 1000));
        // console.log(d);
        // console.log("token expirado" + (token.exp * 1000 < d));
        if (token.exp * 1000 < d) {
            accessToken = await getNewToken();
        }
    }
    else {
        accessToken = await getNewToken();
    }
    return accessToken;
}

async function getNewToken() {
    let response = postOpen(AuthApiURL + "token", {refreshToken: JSON.parse(sessionStorage.getItem("user")).refreshToken});
    sessionStorage.setItem("user", JSON.stringify(response));
    // console.log("setItem");
    return response.accessToken;
}