import React, { createContext, useState, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';

import Navbar from "./global/components/Navbar";
import Login from "./global/pages/login";
import TrocaSenha from "./global/pages/trocaSenha";
import Admin from "./admin/pages/admin";
import Links from "./global/data/links.json"
import Importar from "./global/pages/importar";
import Importacoes from "./global/pages/importacoes";
import AuthProvider from "./global/components/AuthProvider";
import PrivateRoute from "./global/components/PrivateRoute";
import AdminRoute from "./global/components/AdminRoute";


export const ColorModeContext = createContext({ toggleColorMode: () => {}, mode: "light" });

function App() {

  const noNavBar = []
  const pagina = window.location.pathname.split("/")[1];
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');


  // const [mode, setMode] = useState('light');
  const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      mode
    }),
    [mode],
  );

  const theme = useMemo(
    () =>
    createTheme(mode === 'dark' ? {
      components: {
        MuiPaper: {
            styleOverrides: {
              root: () => ({
                boxShadow:
                "0px 6px 6px -3px #577f99, 0px 4px 1px #577f99,0px 4px 18px 3px #577f99",
              }),
            },
          },
          MuiCardHeader: {
            styleOverrides: {
              root: () => ({
                background: 'rgb(5, 30, 52)',
              }),
            },
          },          
        },
      palette: { 
        mode,
        primary: { main: 'rgb(102, 157, 246)' },
        background: { paper: 'rgb(5, 30, 52)',
                    default: '#373737'},        
      },
    } : {
      components: {
        MuiCardHeader: {
            styleOverrides: {
              root: ({ theme }) => ({
                background:
                theme.palette.primary.main,
                color: "#fff"
              }),
            },
          },
          MuiListSubheader: {
            styleOverrides: {
              root: ({ theme }) => ({
                background:
                theme.palette.primary.main,
                color: "#fff"
              }),
            },
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: ({ theme }) => ({
                background:
                theme.palette.primary.main,
                color: "#fff"
              }),
            }            
          },
          MuiIconButton: {
            styleOverrides: {
              sizeMedium : () => ({
                color: "#fff"
              }),
            }
          },
          MuiDrawer: {
            styleOverrides: {
              paperAnchorLeft  : () => ({
                backgroundColor:  theme.palette.primary.main
              }),
            }
          }
        },      
    }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
    <AuthProvider>
        { !noNavBar.includes(pagina) && <Navbar />}
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/login" element={<Login/>} />
          <Route element={<PrivateRoute />}>
            <Route element={<AdminRoute/>}>
              <Route path="/admin" element={<Admin/>} />
              <Route path="/importar" element={<Importar/>} />
              <Route path="/importar/:tipo" element={<Importar/>} />
              <Route path="/importacoes/:_id" element={<Importacoes/>} />
            </Route>
            <Route path="/trocasenha" element={<TrocaSenha/>} />
            {Links.map((link)=> {
                const Componente = require(`${link.component}`).default;
                return (<React.Fragment key={link.link}><Route key={link.route} path={"/" + link.route} element={<Componente/>} />
                          {link?.routeparam && <Route key={link.link + "param"} path={"/" + link.link + link.routeparam} element={<Componente/>} />}</React.Fragment>);
              }
            )}
          </Route>
        </Routes>
        </AuthProvider>
      </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
export default App;