import { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Links from "../data/links.json";
import TrocaTema from "./TrocaTema";
import { useAuth } from "./AuthProvider";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const auth = useAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogOut() {
    setAnchorEl(null);
    setOpenDrawer(false);
    auth.logOut();
  } 

  return (
    <>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} sx={{ color: "white" }}>
        <MenuIcon fontSize="large"/>
      </IconButton>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List sx={{minWidth: "10rem"}}>
          <TrocaTema/>
          {auth.user && (
          Links.map((link=>
            <ListItem key={link.route} onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to={"/" + link.route} className="link">{link.link}</Link>
              </ListItemText>
            </ListItem>
          )))}
          {auth.isAdmin() && <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/admin" className="link">Admin</Link>
              </ListItemText>
            </ListItem>}
            {auth.user && (<IconButton id="perfil" sx={{ml: 1 , color: "white" }} onClick={handleClick}>
                <AccountCircleIcon fontSize="large"/>
              </IconButton>
            )}
        </List>
      </Drawer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem divider>Logado como {auth?.user?.username?.toUpperCase()}<br/>
        Permissões: {auth?.user?.roles?.join(" | ")}</MenuItem>
        <MenuItem component={Link} to="/trocasenha" href="/trocasenha" divider>Alterar senha</MenuItem>
        <MenuItem onClick={handleLogOut}><LogoutIcon fontSize="small"/> Sair</MenuItem>
      </Menu>  
    </>
  );
}
export default DrawerComponent;