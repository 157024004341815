import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DialogContent } from '@mui/material';

function ConfirmaDialog({titulo = "Alerta", msg, open, setOpen, handleConfirma}) {

    msg = msg && msg.split("<br>");

	function handleCloseDialog(confirma) {
        handleConfirma(confirma);
        setOpen(false);
	}

    return (
        <Dialog
        open={open}
        onClose={()=>handleCloseDialog(false)}
        >
            <DialogTitle id="alert-dialog-title">
                {titulo}
            </DialogTitle>
            <DialogContent sx={{justifyContent: (msg.length > 1) ? "left" : "center"}}>
                {msg && msg.map((linha, index)=>(<React.Fragment key={index}>{linha}<br /></React.Fragment>))}
            </DialogContent>	
            <DialogActions sx={{alignSelf: "center"}}>
                <Button onClick={()=>handleCloseDialog(true)} variant="contained">Sim</Button>
                <Button onClick={()=>handleCloseDialog(false)} variant="contained">Não</Button>
            </DialogActions>
        </Dialog>	
    )
}

export default ConfirmaDialog;