import useSWR from "swr";
import {fetchWrapper} from "../../global/helpers/fetchWrapper";

const CasoApiUrl = process.env.REACT_APP_API_URL + "/caso";

export function useGetAllCasos () {
  const { data, error, isLoading } = useSWR(`${CasoApiUrl}s`, fetchWrapper.get)
 
  return {
    casos: data,
    isLoading,
    isError: error
  }
}

export function useGetBuscaCaso (search = null, tag = null, page = 1, tipoVinculo = null, vinculo = null, completa = false) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${PessoaApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&bl=${bl}`);
  const { data, error, isLoading } = useSWR((search || tag || (tipoVinculo && vinculo) || completa === true) ? `${CasoApiUrl}s/busca?search=${search}&tag=${tag}&page=${page}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&completa=${completa}` : null, fetchWrapper.get);
  // console.log(data);
 
  return {
    casos: data || {casos:[]},
    isLoading,
    isError: error
  }
}

export function useGetEstatisticasCaso (search = null, tag = null, tipoVinculo = null, vinculo = null) {
  tipoVinculo = tipoVinculo || null;
  // console.log(`${CasoApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}&nc=${nc}&nnc=${nnc}&bl=${bl}&obs=${obs}&nobs=${nobs}&indicacao=${indicacao}`);
  const { data, error, isLoading } = useSWR(`${CasoApiUrl}s/estatisticas?search=${search}&tag=${tag}&tipovinculo=${tipoVinculo}&vinculo=${vinculo}`, fetchWrapper.get);
 
  // console.log(isLoading);
  // console.table(data);

  return {
    casos: data || {},
    isLoading,
    isError: error
  }
}

export function useGetLastCasos (page = 1) {
  const { data, error, isLoading } = useSWR(`${CasoApiUrl}s/last?page=${page}`, fetchWrapper.get)
 
  return {
    casos: data,
    isLoading,
    isError: error
  }
}

export function useGetCaso (id) {
    const { data, error, isLoading, mutate } = useSWR(id ? `${CasoApiUrl}/${id}` : null, fetchWrapper.get)
    // console.log(data);

    return {
      caso: data,
      isLoading,
      isError: error,
      mutate
    }
}

export function patchCaso (caso) {
  return fetchWrapper.patch(CasoApiUrl, caso);
}

export function postCaso (caso) {
  return fetchWrapper.post(CasoApiUrl, caso);
}

export function delCaso (caso) {
  return fetchWrapper.delete(CasoApiUrl, caso);
}
