import { Grid } from "@mui/material";

export default function PaginaBase({children, height = 'calc(100vh - 70px)', xsSpacing = 0}) {
    return (
    <Grid container justifyContent="center" spacing={{xs: xsSpacing, md: 4}} sx={{margin: '0.1rem',
        paddingLeft: '0.5rem',
        paddingRight: '0.6rem',
        paddingBottom: '1rem',
        overflow: 'auto',
        height,
        paddingTop:'0px',
        maxWidth: '99%',
        backgroundImage: "url(\"./logo.png\")",
        backgroundSize: "60%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"}}
        style={{        marginTop: '0px',
        marginLeft: '0px'}}

        >
      {children}
    </Grid>
    )
}
  