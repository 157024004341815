//:TODO BOTAO ADICIONAR MAIS PRO CANTO?

import { useState, useContext } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {DialogTitle} from '@mui/material';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import AddCircle from "@mui/icons-material/AddCircle";
import { Tooltip } from '@mui/material';

import {ColorModeContext} from "../../App";
import Lista from './Lista';
import { Clear, Webhook } from '@mui/icons-material';

function AddVinculoDialog({open, setOpen, obj = {}, setObj, tipo, setEditado}) {

    if (tipo === null) tipo = "pessoa";

    const colorMode = useContext(ColorModeContext);

    let tipofn = `useGetBusca${tipo.charAt(0).toUpperCase() + tipo.slice(1)}`;

    if(tipo !== null && !Array.isArray(obj[tipo+"s"])){
      obj[tipo+"s"] = [];
    }


    // console.log(colorMode);
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const busca = require("../../" + tipo + "/services/" + tipo + "Service.js")[tipofn];
    // console.log(busca, "../../" + tipo + "/services/" + tipo + "Service.js", tipofn);
    const { [`${tipo}s`]: itens, isLoading } = busca(inputValue, null, 1);

    // console.log(obj);
    // console.log(tipo)
	function handleCloseDialog() {
    setInputValue('');
    setItemSelecionado(null);
    setOpen(false);
	}

  function handleDelItem(itemtoDelete) {
      setEditado(true);
      setObj(prevState => { return {...prevState, [tipo + "s"]: prevState[tipo + "s"].filter((item) => item._id !== itemtoDelete._id)} }); 
  }

  function handleAddVinculo() {
    setEditado(true);
    // console.log(itemSelecionado);
    // console.log({...obj, [tipo + "s"]: [...obj[tipo + "s"], itemSelecionado]});
    let {_id} = itemSelecionado ?  itemSelecionado : {_id: null};
    if (_id) {
      if(obj[tipo+"s"].findIndex((x)=> x._id === _id) === -1 ) {
        setObj(prevState => { return {...prevState, [tipo + "s"]: [...prevState[tipo + "s"], itemSelecionado]}}); 
      }
    }
    else {
      if(inputValue !== "") {
        if(obj[tipo+"s"].findIndex((x)=> x._id.toLocaleLowerCase() === ("novo" + inputValue).toLocaleLowerCase()) === -1 ) {
          setObj(prevState => { return {...prevState, [tipo + "s"]: [...prevState[tipo + "s"], {_id: "novo" + inputValue, nome: inputValue}]}}); 
        }
      }
    }
  }

  function handleChangeAdd(e, newItem)
  {
    // e.preventDefault();
    // console.log(newItem);

    if(newItem) {
      let {_id, nome} = newItem;
      setItemSelecionado({_id , nome});
      // console.log(itemSelecionado);
    }
    else {
      setItemSelecionado(null);
    }
  }    

    // useEffect(() => {
     
    //   console.log(obj);
    // }, [obj]);

    return (
        <Dialog
        open={open}
        onClose={()=>handleCloseDialog()}
        >	
            <DialogTitle id="alert-dialog-title">
                Editar {tipo}s de {obj?.nome}
            </DialogTitle>	        
            <DialogContent>
                <Autocomplete
                    id="add"
                    name="add"
                    value={itemSelecionado || ""}
                    inputValue={inputValue}
                    options={itens[`${tipo}s`]}
                    getOptionLabel={(option) => option?.nome || "" }
                    isOptionEqualToValue={(option, value) => (option._id === value._id)}    
                    renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.nome}
                        </li>
                    )}
                    onChange={(e, newValue) => handleChangeAdd(e, newValue)}  
                    onInputChange={(e, newInputValue) => {
                      setInputValue(newInputValue);
                    }}                            
                    renderInput={(params) => <TextField label="Buscar" {...params} InputProps={{...params.InputProps, ...params.InputProps.ref,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Adicionar">
                          <IconButton onClick={()=> handleAddVinculo()}>
                            <AddCircle color={colorMode.mode === "light" ? "primary" : ""}/>
                          </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }} ></TextField>}
                    freeSolo clearIcon="false" loading={isLoading} loadingText="Carregando..." sx={{marginTop: "0.4rem", marginBottom: "1rem"}}/>
                <Lista icone={<Webhook/>} titulo={`Vínculos com ${tipo}s`} linkTitulo={`${tipo}s`} itens={obj[tipo + "s"]} linkItem={`${tipo}s`} btnItem={<Clear color={colorMode.mode === "light" ? "primary" : ""}/>} btnItemInfo={"Remover"} btnItemFn={handleDelItem}/>

            </DialogContent>

            <DialogActions sx={{alignSelf: "center"}}>
                <Button onClick={handleCloseDialog} variant="contained">Fechar</Button>
            </DialogActions>
        </Dialog>	
    )
}

export default AddVinculoDialog;