import { useState } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import { ReactComponent as Logo } from '../../assets/intel4.svg';

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import Links from "../data/links.json";
import TrocaTema from "./TrocaTema";
import { useAuth } from "./AuthProvider";

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const auth = useAuth();

  // console.log(auth.user);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogOut() {
    setAnchorEl(null);
    auth.logOut();
  }

  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar sx={{ paddingLeft: "0px" }}>
        {isMobile ? (
          <>
            <DrawerComponent />
            <Typography
              variant="h5"
              className="logo"
              sx={{ paddingLeft: "15px", fontWeight: 600 }}
            >
              Crawler Vault
            </Typography>
            <Logo style={{maxHeight: "4.3rem"}}/>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              sx={{ fontWeight: 600, paddingLeft: "10px" }}
            >
              Crawler Vault
            </Typography>
            <Logo style={{paddingLeft: "10px", maxHeight: "4.3rem"}}/>
            <span  className="logo"/>
            <TrocaTema />
            <div className="navlinks">
            {auth.user && (Links.map(
                (link) =>
                  !link.onlymobile && (
                    <Link
                      key={link.route}
                      to={"/" + link.route}
                      className="link1"
                    >
                      {link.link}
                    </Link>
                  )
              ))}
              {auth.isAdmin() && (
                <Link to="/admin" className="link1">
                  Admin
                </Link>
              )}
            </div>
            {auth.user && (
              <IconButton id="perfil" sx={{ color: "white" }} onClick={handleClick}>
                <AccountCircleIcon />
              </IconButton>
            )}
          </>
        )}
      </Toolbar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem divider>Logado como {auth?.user?.username?.toUpperCase()}<br/>
        Permissões: {auth?.user?.roles?.join(" | ")}</MenuItem>
        <MenuItem component={Link} to="/trocasenha" href="/trocasenha" divider>Alterar senha</MenuItem>
        <MenuItem onClick={handleLogOut}><LogoutIcon fontSize="small"/> Sair</MenuItem>
      </Menu>      
    </AppBar>
  );
}
export default Navbar;
